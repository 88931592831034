import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import { connect, useSelector } from "react-redux";
import {Badge, Button, Col, Container, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap";
import { DateTime, IANAZone } from 'luxon';
import { isNullOrUndefindedAndEmpty } from "../../utils/pantallaUtil";
import io from "socket.io-client";
import ProgressBar from "../../components/Common/progressBar";
import {isNullOrUndefined} from "../../utils/functionsUtils";
import {disconnectSocket, initiateSocket, subscribeToProcess} from "../../hooks/socket.client";


const SOCKET_SERVER_URL = process.env.REACT_APP_REPORT_URL;

const PdfTracking = ({
  reduxPostPdfTracking,
  reduxSetPDFTracking,
  pdfBase64,
}) => {
  const [selectedOption, setSelectedOption] = useState("1");
  const { 
    register, 
    handleSubmit,
    setValue, 
    formState: { errors } 
  } = useForm();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [progressStatus, setProgressStatus] = useState({
    successCount: 0,
    errorCount: 0,
    total: 0
  })
  const [processing, setProcessing] = useState(false)
  const [urlStoraged, setUrlStoraged] = useState(localStorage.getItem('urlStoragedLabelsPDF'))
  const [showDownloadButton, setShowDownloadButton] = useState(true)

  
  const validateSession = useSelector(state=> state.loadingState.validateSession);

  useEffect(() => {
    initiateSocket(localStorage.getItem('token'));
    subscribeToProcess((err, payload) => {
      if (err) return;

      localStorage.setItem('urlStoragedLabelsPDF', payload.urlDownload)

      setUrlStoraged(payload.urlDownload);

      if (!isNullOrUndefindedAndEmpty(payload.urlDownload)) {
        const link = document.createElement('a');
        link.href = payload.urlDownload;
        link.target = '_blank';
        link.download = `${DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd')}-${Date.now()}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      setProcessing(false)
    }, 'documentReady')

    subscribeToProcess((err, payload) => {
      if (err) return;

      setProgressStatus(payload)
      if (payload.total !== (payload.successCount + payload.errorCount)) {
        setShowDownloadButton(false)
      }else {
        setShowDownloadButton(true)
      }
      console.log(payload)

    }, 'update')

    return () => {
      disconnectSocket()
    }
  },[])

  const onSubmit = async (data) => {
    let dataPost = {};
    let isValid = true;
    const lines = data.ids.split("\n");
    
    for (let line of lines) {
      const ids = line.trim().split(/\s+/);
      if (ids.length > 1) {
        setErrorMessage("¡Recuerda agregar solo una Guia o Id por linea!");
        isValid = false;
        break;
      }
    }  
    
    if (isValid) {
      setErrorMessage("");
      dataPost = {
        type: Number(selectedOption),
        ids: data.ids.split("\n").map((id) => id.trim()),
      };
      reduxPostPdfTracking(dataPost);
      setFormSubmitted(true);
      setProcessing(true);
    }
  };

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
    setValue("ids", "");
  };

  const handleDownloadLabelsPdf = () => {
    const link = document.createElement('a');
    link.href = urlStoraged;
    link.target = '_blank';
    link.download = `${DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd')}-${Date.now()}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setProcessing(false)
    console.log(urlStoraged)
    disconnectSocket()
  }

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Etiquetas</h1>
              </div>
              <p className="mb-4">Módulo de generación de Etiquetas</p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Preparar Etiquetas en Formato PDF
                  </h6>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <table style={{ width: "100%" }}>
                      <tr className="row">
                        <td style={{ width: "30%" }}>
                          <div className="form-group col-md-12">
                            <input 
                              type="radio" 
                              value="1" 
                              {...register("byTrackings")}
                              checked={selectedOption === "1"}
                              onChange={handleRadioChange}
                            /> Guías
                            <input 
                              type="radio" 
                              value="2" 
                              style={{ marginLeft: '40px'}}
                              {...register("byAddress")}
                              checked={selectedOption === "2"}
                              onChange={handleRadioChange}
                            /> ID Dirección
                            <textarea
                              id="ids"
                              className={`form-control form-control-user ${
                                errors.trackings && "is-invalid"
                              }`}
                              {...register("ids", {required: true})}                              
                              placeholder={
                                selectedOption === "1" 
                                  ? "Ingrese las guías una por linea."
                                  : "Ingrese los ID Direcciones uno por linea."
                              }
                              rows={12}
                              cols={8}
                            />
                            {errors.trackings && (
                              <span className="invalid-feedback">
                                Numeros de guías o Ids no válidos
                              </span>
                            )}
                            {errorMessage && (
                              <span className="text-danger">
                                {errorMessage}
                              </span>
                            )}
                          </div>
                          <div className="d-flex form-group justify-content-start">
                            { !processing && (
                                <Button variant="primary" type="submit" id="btnSearch">
                                  Generar PDF de Etiquetas
                                </Button>
                            )}
                          </div>
                        </td>
                        <td className="d-flex flex-column justify-content-ceter align-content-center"
                            style={{width: "70%"}}>
                          <label>Progreso</label>
                          <ProgressBar procesadas={progressStatus.successCount + progressStatus.errorCount}
                                       total={progressStatus.total}/>
                          {
                               !processing &&  (
                                  <Container>
                                    <Row xs="auto">
                                      <Col>
                                        <Badge bg="danger" className={'text-white'} title={'Documentos no procesados'}>No Exitosos {progressStatus.errorCount}</Badge>
                                      </Col>
                                      <Col>
                                        <Badge bg="success" className={'text-white'} title={'Documentos Generados'}>Exitosos {progressStatus.successCount}</Badge>
                                      </Col>
                                    </Row>
                                  </Container>
                              )
                          }

                          {!isNullOrUndefined(urlStoraged) && showDownloadButton && !processing && (
                              <>
                                <OverlayTrigger placement="bottom" overlay={<Tooltip
                                    id='button-tooltip'>{'Descarga el ultimo documento procesado'}</Tooltip>}>
                                  <Button variant={'info'} onClick={handleDownloadLabelsPdf}
                                          className={'w-50 ml-4 mt-2'} size={'sm'}> Descargar Etiquetas </Button>
                                </OverlayTrigger>
                              </>
                          )}
                          {processing && (
                              <>
                                <Spinner className={'ml-4 mt-2'} animation="border" variant="info"/>
                              </>
                          )}
                        </td>
                      </tr>
                    </table>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pdfBase64: state.pdfTrackingState.pdfBase64,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxPostPdfTracking: (payload) =>
      dispatch({
        type: "TRACKING_OR_EXTERNALID_PDF_SUCCESS",
        value: payload,
      }),
    reduxSetPDFTracking: (payload) =>
      dispatch({
        type: "RESET_PDF_TRACKING_FORM",
        value: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PdfTracking);