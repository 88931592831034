import React, { useEffect, useRef } from "react";
import { Modal, Form } from "react-bootstrap";


const DetailAdjustmentModal = ({ 
    selectedAjustment, 
    selectedMovementTypeName, 
    showModalDetails, 
    handleCloseModal, 
}) => {

    const tableHeaders = [
        "Tipo de ajuste",
        "Bodega",
        "Producto",
        "Cantidad del ajuste",
        "Creado por",
    ];
    
    return (
        <>
            <Modal
                show={showModalDetails}
                onHide={handleCloseModal}
                onEscapeKeyDown={handleCloseModal}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="d-flex justify-content-between align-items-center">
                            Detalles del ajuste
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-bordered dataTable table-sm ">
                        <thead>
                            <tr>
                                {tableHeaders.map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {selectedAjustment?.details.map((aEl, index) => (
                                <tr key={index}>
                                    <td>{selectedMovementTypeName}</td>
                                    <td>{aEl.inventory.warehouse.name}</td>
                                    <td>{aEl.inventory.product.name}</td>
                                    <td>{aEl.quantity}</td>
                                    <td>{`${selectedAjustment.requestingUser.firstName} ${selectedAjustment.requestingUser.lastName}`}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DetailAdjustmentModal;