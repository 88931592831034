import movements from "../../components/Modal/WMS/Inventory/Report/movements";

const initialState = {
  requestingFetchIncomings: false,
  successfulFetchIncomings: false,
  errorsFetchIncomings: false,
  incomings: null,
  requestingCreateManifest: false,
  successfulCreateManifest: false,
  errorsCreateManifest: false,
  createManifestResult: null,
  requestingFetchWMSOrderForReturn: false,
  successfulFetchWMSOrderForReturn: false,
  errorsFetchWMSOrderForReturn: false,

  requestingFetchWMSOrderForRelocation: false,
  successfulFetchWMSOrderForRelocation: false,
  errorsFetchWMSOrderForRelocation: false,

  wmsOrderForReturn: null,

  requestingFetchReturns: false,
  successfulFetchReturns: false,
  errorsFetchReturns: false,
  returns: null,

  requestingCreateWMSReturn: false,
  successfulCreateWMSReturn: false,
  errorsCreateWMSReturn: false,
  createWMSReturnResult: null,

  requestingDownloadReturnCertificate: false,
  successfulDownloadReturnCertificate: false,
  errorsDownloadReturnCertificate: false,
  returnCertificateData: null,

  requestingDownloadMovements: false,
  successfulDownloadMovements: false,
  errorsDownloadMovements: false,
  requestingMovements: false,
  successfulMovements: false,
  errorsMovements: false,
  movements: false,

  requestingProcessReturn: false,
  successfulProcessReturn: false,
  errorsProcessReturn: false,
  processReturnResult: null,

  requestingProcessReturnOrder: false,
  successfulProcessReturnOrder: false,
  errorsProcessReturnOrder: false,
  processReturnOrderResult: null,

  requestingConfirmReturn: false,
  successfulConfirmReturn: false,
  errorsConfirmReturn: false,
  confirmReturnResult: null,

  requestingMassiveReturn: false,
  successfulMassiveReturn: false,
  errorsMassiveReturn: false,
  massiveReturnResult: null,
};

const wmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_WMS_INCOMINGS_REQUESTING":
      return {
        ...state,
        requestingFetchIncomings: true,
        successfulFetchIncomings: false,
        errorsFetchIncomings: false,
      };
    case "FETCH_WMS_INCOMINGS_SUCCESS":
      return {
        ...state,
        requestingFetchIncomings: false,
        successfulFetchIncomings: true,
        errorsFetchIncomings: false,
        incomings: action.value,
      };
    case "FETCH_WMS_INCOMINGS_ERROR":
      return {
        ...state,
        requestingFetchIncomings: false,
        successfulFetchIncomings: false,
        errorsFetchIncomings: true,
      };
    case "CREATE_MANIFEST_REQUESTING":
      return {
        ...state,
        requestingCreateManifest: true,
        successfulCreateManifest: false,
        errorsCreateManifest: false,
      };
    case "CREATE_MANIFEST_SUCCESS":
      return {
        ...state,
        requestingCreateManifest: false,
        successfulCreateManifest: true,
        errorsCreateManifest: false,
        createManifestResult: action.value,
      };
    case "CREATE_MANIFEST_ERROR":
      return {
        ...state,
        requestingCreateManifest: false,
        successfulCreateManifest: false,
        errorsCreateManifest: true,
        createManifestResult: action.value,
      };
    case "FETCH_WMS_ORDER_FOR_RETURN_REQUESTING":
      return {
        ...state,
        requestingFetchWMSOrderForReturn: true,
        successfulFetchWMSOrderForReturn: false,
        errorsFetchWMSOrderForReturn: false,
      };
    case "FETCH_WMS_ORDER_FOR_RETURN_SUCCESS":
      return {
        ...state,
        requestingFetchWMSOrderForReturn: false,
        successfulFetchWMSOrderForReturn: true,
        errorsFetchWMSOrderForReturn: false,
        wmsOrderForReturn: action.value,
      };
    case "FETCH_WMS_ORDER_FOR_RETURN_ERROR":
      return {
        ...state,
        requestingFetchWMSOrderForReturn: false,
        successfulFetchWMSOrderForReturn: false,
        errorsFetchWMSOrderForReturn: true,
        wmsOrderForReturn: null,
      }
    case "RESET_WMS_ORDER_FOR_RETURN":
      return {
        ...state,
        successfulFetchWMSOrderForReturn: false,
        errorsFetchWMSOrderForReturn: false,
        wmsOrderForReturn: null
      };
    case "RESET_WMS_MASSIVE_FOR_RETURN":
      return{
        ...state,
        successfulMassiveReturn: false,
        massiveReturnResult: null,
        errorsMassiveReturn: false,
      }
    case "FETCH_WMS_ORDER_FOR_RELOCATION_REQUESTING":
      return {
        ...state,
        requestingFetchWMSOrderForRelocation: true,
        successfulFetchWMSOrderForRelocation: false,
        errorsFetchWMSOrderForRelocation: false,
      };
    case "FETCH_WMS_ORDER_FOR_RELOCATION_SUCCESS":
      return {
        ...state,
        requestingFetchWMSOrderForRelocation: false,
        successfulFetchWMSOrderForRelocation: true,
        errorsFetchWMSOrderForRelocation: false,
        wmsOrderForRelocation: action.value,
      };
    case "FETCH_WMS_ORDER_FOR_RELOCATION_ERROR":
      return {
        ...state,
        requestingFetchWMSOrderForRelocation: false,
        successfulFetchWMSOrderForRelocation: false,
        errorsFetchWMSOrderForRelocation: true,
        wmsOrderForRelocation: null,
      }
    case "RESET_WMS_ORDER_FOR_RELOCATION":
      return {
        ...state,
        requestingFetchWMSOrderForRelocation: false,
        successfulFetchWMSOrderForRelocation: false,
        errorsFetchWMSOrderForRelocation: false,
        wmsOrderForRelocation: null,
      }
    case "RESET_MANIFEST_FORM":
      return initialState;
    case "FETCH_WMS_RETURNS_REQUESTING":
      return {
        ...state,
        requestingFetchReturns: true,
        successfulFetchReturns: false,
        errorsFetchReturns: false,
      };
    case "FETCH_WMS_RETURNS_SUCCESS":
      return {
        ...state,
        requestingFetchReturns: false,
        successfulFetchReturns: true,
        errorsFetchReturns: false,
        returns: action.value,
      };
    case "FETCH_WMS_RETURNS_ERROR":
      return {
        ...state,
        requestingFetchReturns: false,
        successfulFetchReturns: false,
        errorsFetchReturns: true,
        returns: null,
      };
    case "CREATE_WMS_RETURN_REQUESTING":
      return {
        ...state,
        requestingCreateWMSReturn: true,
        successfulCreateWMSReturn: false,
        errorsCreateWMSReturn: false,
      };
    case "CREATE_WMS_RETURN_SUCCESS":
      return {
        ...state,
        requestingCreateWMSReturn: false,
        successfulCreateWMSReturn: true,
        errorsCreateWMSReturn: false,
        createWMSReturnResult: action.value,
      };
    case "CREATE_WMS_RETURN_ERROR":
      return {
        ...state,
        requestingCreateWMSReturn: false,
        successfulCreateWMSReturn: false,
        errorsCreateWMSReturn: true,
        createWMSReturnResult: action.value?.data || action.value,
      };
    case "DOWNLOAD_RETURN_CERTIFICATE_REQUESTING":
      return {
        ...state,
        requestingDownloadReturnCertificate: true,
        successfulDownloadReturnCertificate: false,
        errorsDownloadReturnCertificate: false,
      };
    case "DOWNLOAD_RETURN_CERTIFICATE_SUCCESS":
      return {
        ...state,
        requestingDownloadReturnCertificate: false,
        successfulDownloadReturnCertificate: true,
        errorsDownloadReturnCertificate: false,
        returnCertificateData: action.value,
      };
    case "DOWNLOAD_RETURN_CERTIFICATE_ERROR":
      return {
        ...state,
        requestingDownloadReturnCertificate: false,
        successfulDownloadReturnCertificate: false,
        errorsDownloadReturnCertificate: true,
      };
    case "FETCH_WMS_MOVEMENTS_REQUEST":
      return {
        ...state,
        requestingMovements: true,
        successfulMovements: false,
        errorsMovements: false,
      };
    case "FETCH_WMS_MOVEMENTS_SUCCESS":
      return {
        ...state,
        requestingMovements: false,
        successfulMovements: true,
        errorsMovements: false,
        movements: action.value,
      };
    case "FETCH_WMS_MOVEMENTS_ERROR":
      return {
        ...state,
        requestingMovements: false,
        successfulMovements: false,
        errorsMovements: true,
      };
    case "DOWNLOAD_WMS_MOVEMENTS_REQUEST":
      return {
        ...state,
        requestingDownloadMovements: true,
        successfulDownloadMovements: false,
        errorsDownloadMovements: false,
      };
    case "DOWNLOAD_WMS_MOVEMENTS_SUCCESS":
      return {
        ...state,
        requestingDownloadMovements: false,
        successfulDownloadMovements: true,
        errorsDownloadMovements: false,
      };
    case "DOWNLOAD_WMS_MOVEMENTS_ERROR":
      return {
        ...state,
        requestingDownloadMovements: false,
        successfulDownloadMovements: false,
        errorsDownloadMovements: true,
      };
    case "PROCESS_WMS_RETURN_REQUESTING":
      return {
        ...state,
        requestingProcessReturn: true,
        successfulProcessReturn: false,
        errorsProcessReturn: false,
      };
    case "PROCESS_WMS_RETURN_SUCCESS":
      return {
        ...state,
        requestingProcessReturn: false,
        successfulProcessReturn: true,
        errorsProcessReturn: false,
        processReturnResult: action.value,
      };
    case "PROCESS_WMS_RETURN_ERROR":
      return {
        ...state,
        requestingProcessReturn: false,
        successfulProcessReturn: false,
        errorsProcessReturn: true,
        processReturnResult: action.value,
      };
    case "PROCESS_WMS_RETURN_ORDER_REQUESTING":
      return {
        ...state,
        requestingProcessReturnOrder: true,
        successfulProcessReturnOrder: false,
        errorsProcessReturnOrder: false,
      };
    case "PROCESS_WMS_RETURN_ORDER_SUCCESS":
      return {
        ...state,
        requestingProcessReturnOrder: false,
        successfulProcessReturnOrder: true,
        errorsProcessReturnOrder: false,
        processReturnOrderResult: action.value,
      };
    case "PROCESS_WMS_RETURN_ORDER_ERROR":
      return {
        ...state,
        requestingProcessReturnOrder: false,
        successfulProcessReturnOrder: false,
        errorsProcessReturnOrder: true,
        processReturnOrderResult: action.value,
      };
    case "CONFIRM_WMS_RETURN_REQUESTING":
      return {
        ...state,
        requestingConfirmReturn: true,
        successfulConfirmReturn: false,
        errorsConfirmReturn: false,
      };
    case "CONFIRM_WMS_RETURN_SUCCESS":
      return {
        ...state,
        requestingConfirmReturn: false,
        successfulConfirmReturn: true,
        errorsConfirmReturn: false,
        confirmReturnResult: action.value,
      };
    case "CONFIRM_WMS_RETURN_ERROR":
      return {
        ...state,
        requestingConfirmReturn: false,
        successfulConfirmReturn: false,
        errorsConfirmReturn: true,
        confirmReturnResult: action.value,
      };
    case "RESET_PROCESS_RETURN":
      return {
        ...state,
        requestingProcessReturn: false,
        successfulProcessReturn: false,
        errorsProcessReturn: false,
        processReturnResult: null,
      };
    case "RESET_PROCESS_RETURN_ORDER":
      return {
        ...state,
        requestingProcessReturnOrder: false,
        successfulProcessReturnOrder: false,
        errorsProcessReturnOrder: false,
        processReturnOrderResult: null,
      };
    case "RESET_CONFIRM_RETURN":
      return {
        ...state,
        requestingConfirmReturn: false,
        successfulConfirmReturn: false,
        errorsConfirmReturn: false,
        confirmReturnResult: null,
      };
    case "RESET_CREATE_WMS_RETURN":
      return {
        ...state,
        successfulCreateWMSReturn: false,
        errorsCreateWMSReturn: false,
        createWMSReturnResult: null
      };
    case "RESET_DOWNLOAD_RETURN_CERTIFICATE":
      return {
        ...state,
        successfulDownloadReturnCertificate: false,
        errorsDownloadReturnCertificate: false
      };
      case "PROCESS_MASSIVE_RETURN_REQUESTING":
        return {
            ...state,
            requestingMassiveReturn: true,
            successfulMassiveReturn: false,
            errorsMassiveReturn: false,
            massiveReturnResult: null,
        };

    case "PROCESS_MASSIVE_RETURN_SUCCESS":
        return {
            ...state,
            requestingMassiveReturn: false,
            successfulMassiveReturn: true,
            errorsMassiveReturn: false,
            massiveReturnResult: action.value,
        };

    case "PROCESS_MASSIVE_RETURN_ERROR":
        return {
            ...state,
            requestingMassiveReturn: false,
            successfulMassiveReturn: false,
            errorsMassiveReturn: true,
            massiveReturnResult: action.value,
        };

    case "RESET_PROCESS_MASSIVE_RETURN":
        return {
            ...state,
            requestingMassiveReturn: false,
            successfulMassiveReturn: false,
            errorsMassiveReturn: false,
            massiveReturnResult: null,
        };

    default:
      return state;
    
  }
};

export default wmsReducer;
