import { connect } from "react-redux";
import { Tooltip } from "chart.js";

import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import PreOrdersTable from "./preOrdersTable";
import CreatePreOrder from "./createPreOrder";

function PreOrders() {
    return <div>
        <div id='wrapper'>
            <Sidebar />
            <div
                id='content-wrapper'
                className='d-flex flex-column'>
                <div id='content'>
                    <Topbar />
                    <div className='container-fluid'>
                        <div className='d-sm-flex align-items-center mb-1'>
                            <h1 className='h3 mb-0 text-gray-800 mr-3'>Créditos</h1>
                            <CreatePreOrder />
                        </div>
                        <div className='card shadow mb-4'>
                            <div className='card-header py-3'>
                                <h6 className='m-0 font-weight-bold text-primary'>Listado de Créditos</h6>
                            </div>
                            <div className='card-body'>
                                <PreOrdersTable />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {

}

const mapDispatchToProps = (dispatch) => {
}

export default connect(mapStateToProps, mapDispatchToProps)(PreOrders);