import React, { useEffect, useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { formatDateWithCustomFormat, FormatsDate } from "../../../utils/formatDate";
import { isNullOrUndefined } from "../../../utils/functionsUtils"
import { Badge, Button } from 'react-bootstrap';

const AdjustmentTimeLine = ({
    show,
    handleClose,
    AdjustmentRecordsById
}) => {

    const [timeline, setTimeline] = useState([]);

    const adjustmentStatus = [
        {
            idStatus: 1,
            description: "Pendiente",
            style: {
                bg: "info",
                icon: "zmdi zmdi-assignment-alert",
                badge: "badge-info",
            }
        },
        {
            idStatus: 2,
            description: "Procesado",
            style: {
                bg: "success",
                icon: "zmdi zmdi-assignment-check",
                badge: "badge-success",
            }
        },
        {
            idStatus: 3,
            description: "Denegado",
            style: {
                bg: "warning",
                icon: "zmdi zmdi-assignment-alert",
                badge: "badge-warning",
            }
        },
    ];

    useEffect(() => {
        const adjustmentRecordsWithStatus  = AdjustmentRecordsById.map(item => {
            const status = adjustmentStatus.find(statusItem => statusItem.idStatus === item.idStatus);
            return {...item, status: status || {}};
        });
    
        setTimeline(adjustmentRecordsWithStatus);
    }, [AdjustmentRecordsById]);

    const getDetailEvent = (ele) => { return renderFullDetail(ele) };

    const renderFullDetail = (ele) => {
        const firstName = ele.user && ele.user.firstName ? ele.user.firstName : null;
        const lastName = ele.user && ele.user.lastName ? ele.user.lastName : null;
        const date = ele.createdDate || null;
        const notes = ele.notes && ele.notes ? ele.notes : null;
        const idUser = ele.idUser && ele.idUser ? ele.idUser : null;
        const status = ele.status || null;

        return (
            <>
                <h2 className="mb-1">
                    <a href="#">
                        {firstName !== null && firstName !== '' && idUser !== null && (
                            `${firstName} ${lastName}`
                        )}
                    </a>{" "}
                    <span>
                        Cambio estado a: <div className={`badge ${status.style.badge}`}>{status.description}</div>
                    </span>
                </h2>
                {date !== null && date !== '' && (
                    <div>
                        <span className="text-muted">Fecha :</span>{" "}
                        {formatDateWithCustomFormat(date, { format: "DD-MM-YYYY HH:mm:ss" })}
                    </div>
                )}
                {notes != null && (
                    <div>
                        <span>Nota :</span> {notes}
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                onEscapeKeyDown={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Timeline</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="card shadow mb-4">
                            <div className="row mt-4">
                                {timeline.length > 0 ? (
                                    timeline.map((ele, key) => (
                                        <div key={key} className="col-md-12">
                                            <ul className="cbp_tmtimeline">
                                                <li>
                                                    <time className="cbp_tmtime" dateTime="2017-10-22T12:13">
                                                        <span>
                                                            {formatDateWithCustomFormat(ele.createdDate, {
                                                                format: FormatsDate.TIME_HOURS,
                                                            })}
                                                        </span>
                                                        <span>
                                                            {formatDateWithCustomFormat(ele.createdDate, {
                                                                format: "DD-MM-YYYY",
                                                            })}
                                                        </span>
                                                    </time>
                                                    <div className={`cbp_tmicon bg-${ele.status.style.bg}`}>
                                                        <i className={`${ele.status.style.icon}`}></i>
                                                    </div>
                                                    <div className="cbp_tmlabel mr-5">{getDetailEvent(ele)}</div>
                                                </li>
                                            </ul>
                                        </div>
                                    ))
                                ) : (
                                    <div className="col-md-12">
                                        <div className="alert alert-warning" role="alert">
                                            No hay elementos para mostrar
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>


                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        AdjustmentRecordsById: state.adjustmentState.AdjustmentRecordsById,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetAdjustmentRecords: (payload) =>
            dispatch({
                type: "FETCH_GET_ADJUSTMENT_RECORDS_BY_ID",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdjustmentTimeLine);
