const initialState = {
  requestingNewAdjusment: false,
  successRequestAdjusment: false,
  errorRequestAdjustment: false,
  createAdjustment: null,
  requestingAdjustmentRecordsById: false,
  errorAdjustmentRecordsById: false,
  successAdjustmentRecordsById: false,
  AdjustmentRecordsById: [],
  requestingPendingAdjustmentList: false,
  errorPendingAdjustmentList: false,
  successPendingAdjustmentList: false,
  pendingAdjustmentList: [],
  requestingHistoricalAdjustmentList: false,
  successHistoricalAdjustmentList: false,
  errorHistoricalAdjustmentList: false,
  historicalAdjustmentList: [],
  requestingAdjustmentDecision: false,
  successAdjustmentDecision: null,
  errorAdjustmentDecision: false,
  AdjustmentDecision: null,
  requestingDownloadCertificate: false,
  errorDownloadCertificate: false,
  successDownloadCertificate: false,
};

const adjustmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUESTING_NEW_ADJUSTMENT":
      return {
        ...state,
        requestingFetchAdjusment: true,
        successfulFetchAdjusment: false,
        errorFetchAdjustment: false
      };
    case "REQUEST_NEW_ADJUSTMENT_SUCCESS":
      return {
        ...state,
        requestingFetchAdjusment: false,
        successfulFetchAdjusment: true,
        errorFetchAdjustment: false,
        createAdjustment: action.value
      };
    case "REQUEST_NEW_ADJUSTMENT_ERROR":
      return {
        ...state,
        requestingFetchAdjusment: false,
        successfulFetchAdjusment: false,
        errorFetchAdjustment: true
      };
    case "RESET_NEW_ADJUSTMENT_FORM":
      return {
        ...state,
        requestingFetchAdjusment: false,
        successfulFetchAdjusment: false,
        errorFetchAdjustment: false,
        createAdjustment: null,
      };
    case "REQUESTING_ADJUSTMENT_RECORDS_BY_ID":
      return {
        ...state,
        requestingAdjustmentRecordsById: true,
        successAdjustmentRecordsById: false,
        errorAdjustmentRecordsById: false,
      };
    case "REQUEST_ADJUSTMENT_RECORDS_BY_ID_SUCCESS":
      return {
        ...state,
        requestingAdjustmentRecordsById: false,
        successAdjustmentRecordsById: true,
        errorAdjustmentRecordsById: false,
        AdjustmentRecordsById: action.value,
      };
    case "REQUEST_ADJUSTMENT_RECORDS_BY_ID_ERROR":
      return {
        ...state,
        requestingAdjustmentRecordsById: false,
        successAdjustmentRecordsById: false,
        errorAdjustmentRecordsById: true,
      };
    case "RESET_ADJUSTMENT_RECORDS_BY_ID":
      return {
        ...state,
        requestingPendingAdjustmentList: false,
        successPendingAdjustmentList: false,
        errorPendingAdjustmentList: false,
        AdjustmentRecordsById: [],
      };
    case "REQUESTING_GET_PENDING_ADJUSTMENT":
      return {
        ...state,
        requestingPendingAdjustmentList: true,
        successPendingAdjustmentList: false,
        errorPendingAdjustmentList: false,
      };
    case "GET_PENDING_ADJUSTMENT_SUCCESS":
      return {
        ...state,
        requestingPendingAdjustmentList: false,
        successPendingAdjustmentList: true,
        errorPendingAdjustmentList: false,
        pendingAdjustmentList: action.value,
      };
    case "GET_PENDING_ADJUSTMENT_ERROR":
      return {
        ...state,
        requestingPendingAdjustmentList: false,
        successPendingAdjustmentList: false,
        errorPendingAdjustmentList: true,
      };
    case "RESET_GET_PENDING_ADJUSTMENT_LIST":
      return {
        ...state,
        requestingPendingAdjustmentList: false,
        successPendingAdjustmentList: false,
        errorPendingAdjustmentList: false,
        pendingAdjustmentList: [],
      };
    case "REQUESTING_GET_HISTORICAL_ADJUSTMENT":
      return {
        ...state,
        requestingHistoricalAdjustmentList: true,
        successHistoricalAdjustmentList: false,
        errorHistoricalAdjustmentList: false,
      };
    case "GET_HISTORICAL_ADJUSTMENT_SUCCESS":
      return {
        ...state,
        requestingHistoricalAdjustmentList: false,
        successHistoricalAdjustmentList: true,
        errorHistoricalAdjustmentList: false,
        historicalAdjustmentList: action.value,
      };
    case "GET_HISTORICAL_ADJUSTMENT_ERROR":
      return {
        ...state,
        requestingHistoricalAdjustmentList: false,
        successHistoricalAdjustmentList: false,
        errorHistoricalAdjustmentList: true,
      };
    case "RESET_GET_HISTORICAL_ADJUSTMENT_LIST":
      return {
        ...state,
        requestingHistoricalAdjustmentList: false,
        successHistoricalAdjustmentList: false,
        errorHistoricalAdjustmentList: false,
        historicalAdjustmentList: [],
      };
    case "REQUESTING_ADJUSTMENT_DECISION":
      return {
        ...state,
        requestingAdjustmentDecision: true,
        successAdjustmentDecision: false,
        errorAdjustmentDecision: false,
      };
    case "ADJUSTMENT_DECISION_SUCCESS":
      return {
        ...state,
        requestingAdjustmentDecision: false,
        successAdjustmentDecision: true,
        errorAdjustmentDecision: false,
        AdjustmentDecision: action.value,
      };
    case "ADJUSTMENT_DECISION_ERROR":
      return {
        ...state,
        requestingAdjustmentDecision: false,
        successAdjustmentDecision: false,
        errorAdjustmentDecision: true,
      };
    case "DOWNLOAD_ADJUSTMENT_CERTIFICATE_REQUESTING":
      return {
        ...state,
        requestingDownloadCertificate: true,
        successDownloadCertificate: false,
        errorDownloadCertificate: false
      };
    case "DOWNLOAD_ADJUSTMENT_CERTIFICATE_SUCCESS":
      return {
        ...state,
        requestingDownloadCertificate: false,
        successDownloadCertificate: true,
        errorDownloadCertificate: false,
        createAdjustment: action.value
      };
    case "DOWNLOAD_ADJUSTMENT_CERTIFICATE_ERROR":
      return {
        ...state,
        requestingDownloadCertificate: false,
        successDownloadCertificate: false,
        errorDownloadCertificate: true
      };
    default:
      return state;
  }
};

export default adjustmentReducer;
