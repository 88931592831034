import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Sidebar from "../../../components/Navigation/Sidebar";
import Topbar from "../../../components/Navigation/Topbar";
import Header from "../../../components/Page/header";
import { buttonIcon, buttonTypes } from "../../../utils/buttonsUtil";
import { TbArrowsLeftRight } from "react-icons/tb";
import Select from 'react-select';

import MultiFile from "../../../components/Inputs/MultiFile";
import Grid from '../../../components/Grid';

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Show } from "../../../hooks/Show";
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2'; 
import { hasNoCountryLimitations } from "../../../utils/functionsUtils";

import { FaRegTrashAlt } from "react-icons/fa";

const customStyles = {
    menu: (base) => ({ ...base, zIndex: 9999 }),
    valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "4.5vw", overflowY: "auto" }),
    control: (baseStyles) => ({
        ...baseStyles,
        flexWrap: "nowrap",
        width: '20vw',
        minWidth: '150px',
        maxWidth: '300px'
    })
};


const largerSelectStyles = {
    ...customStyles,
    control: (baseStyles) => ({
        ...baseStyles,
        flexWrap: "nowrap",
        flex: 1,
        minWidth: '0',
        width: '100%',
        maxWidth: 'none'
    })
};


const RelocationWms = ({ shippers, warehouses, products, reduxGetShippers, errorTranslation, successfulTranslation, reduxGetWarehouses, reduxGetProducts, reduxTranslationWMSOrder, reduxGetInventory, dispatch, downloadManifest, inventory }) => {
    const history = useHistory();
    const [selectedOriginHub, setSelectedOriginHub] = useState(null);
    const [selectedShipper, setSelectedShipper] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [comments, setComments] = useState('');
    const [selectedHub, setSelectedHub] = useState(null); 
    const [selectedReceivedHub, setSelectedReceivedHub] = useState(null);
    const [availableInventory, setAvailableInventory] = useState(null); 

    const subHubsArr = [
        { id: 1, hub: "Disponible", stocktype: 'availableStock' },
        { id: 2, hub: "Caducados", stocktype: 'expiredStock' },
        { id: 3, hub: "Averiados", stocktype: 'damagedStock' },
        { id: 4, hub: "Sobrantes", stocktype: 'surplusStock' },
        { id: 5, hub: "Faltantes", stocktype: 'missingStock' },
        { id: 6, hub: "Suplantaciones", stocktype: 'supplantingStock' }
    ];

    const userData = JSON.parse(window.localStorage.getItem('userData'));

    const hasInventoryAdminPermission = userData.role.permissionsRoles.some(
        permissionRole => permissionRole.permission.name === 'feature:wms-inventory-admin'
    );

    const countryUser = userData.idCountry ? userData.idCountry : null;
    const countryUserStr = countryUser ? countryUser.toString() : null;
    const viewAllCountriesData = hasNoCountryLimitations(userData);
    const userHub = userData.idHub ? userData.idHub : null;
    const userHubStr = userHub ? userHub.toString() : null;
    const [subWarehouseTypesRepient, setSubWarehouseTypesRepient] = useState([]);

    const {
        control,
        formState: { errors },
        setValue,
        getValues,
        clearErrors,
        watch,
    } = useForm({
        defaultValues: {
            warehouseOrigin: null,
            warehouseReceived: null,
            shipper: null,
            product: null,
            totalDeclaredQty: null,
            warehouseOriginHub: null,
        }
    });

    const warehouseOriginWatcher = watch('warehouseOrigin', null);
    const warehouseReceivedWatcher = watch('warehouseReceived', null);
    const warehouseOriginHubWatcher = watch('warehouseOriginHub', null);

    useEffect(() => {
        if (!hasInventoryAdminPermission && !userData.idHub) {
            Swal.fire(
                "No permitido",
                `Lo sentimos pero su usuario no está asociado a ningún HUB o el HUB no tiene asociada una bodega`,
                "error"
            );
            history.goBack();
            return;
        }
    }, [hasInventoryAdminPermission, userData.idHub])



    useEffect(() => {
        if (!selectedShipper || !selectedShipper.value) return;
    
        setValue('shipper', selectedShipper);
    
        if (warehouses && warehouses.items && userHub) {
    
            const userWarehouse = warehouses.items.find(warehouse => 
                warehouse.warehouseHubs && warehouse.warehouseHubs.some(hub => hub.idHub === userHub)
            );
    
            if (userWarehouse) {
    
                setValue('warehouseOrigin', {
                    value: userWarehouse.idWarehouse,
                    label: userWarehouse.name || userWarehouse.warehouseCode,
                });
    
                // Eliminamos esta parte para que no se auto-seleccione el hub
                // const userHubOption = subHubsArr.find(hub => hub.id === userHub);
                // if (userHubOption) {
                //     setValue('warehouseOriginHub', {
                //         value: userHubOption.id,
                //         label: userHubOption.hub,
                //     });
                //     setSelectedOriginHub(userHubOption); 
                // }
            }
        }
    }, [selectedShipper, reduxGetProducts, setValue]);

    useEffect(() => {
        if(warehouseOriginWatcher?.value && selectedShipper?.value){
            reduxGetInventory({
                page: 1,
                offset: 1000,
                shipper: selectedShipper.value,
                warehouses:  warehouseOriginWatcher.value,
            });
        }
    }, [warehouseOriginWatcher, selectedShipper]);

    useEffect(() => {
        if (errorTranslation) {
            Swal.fire({
                icon: 'error',
                title: 'Error al trasladar la orden',
                text: 'Debe ingresar al menos un comentario u observación',
            });
        }
    }, [errorTranslation]);

    useEffect(() => {
        if (successfulTranslation) {
            Swal.fire({
                icon: 'success',
                title: 'Proceso exitoso',
                text: successfulTranslation.message,
                confirmButtonText: 'OK',
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
        }
    }, [successfulTranslation]);

    useEffect(() => {
        if (successfulTranslation && successfulTranslation.manifest) {
            const idManifest = successfulTranslation.manifest.idManifest;
            downloadManifest(idManifest)
        }
    }, [successfulTranslation, downloadManifest]);


    useEffect(() => {
        const filterWarehouses = {
            page: 1,
            offset: 1000
        }
        if (!viewAllCountriesData && userData?.idCountry)
            filterWarehouses['search'] = `TMB.idCountry|${userData?.idCountry}`;

        reduxGetShippers();
        reduxGetWarehouses(filterWarehouses);
    }, [reduxGetShippers, reduxGetWarehouses]);

    useEffect(() => {
        if (warehouses && warehouses.items && userHub) {
            const userWarehouse = warehouses.items.find(warehouse => 
                warehouse.warehouseHubs && warehouse.warehouseHubs.some(hub => hub.idHub === userHub)
            );

            if (userWarehouse) {
                setValue('warehouseOrigin', {
                    value: userWarehouse.idWarehouse,
                    label: userWarehouse.name || userWarehouse.warehouseCode,
                });
                
                const userHubOption = subHubsArr.find(hub => hub.id === userHub);
                if (userHubOption) {
                    setSelectedOriginHub(null);
                    setValue('warehouseOriginHub', null);
                }
            }
        }
    }, [warehouses, userHub, setValue]);

    const actionButtons = [
        {
            onClick: () => history.goBack(),
            description: "Regresar a inventario",
            buttonType: buttonTypes.Primary,
            buttonIcon: buttonIcon.Arrow_LeftReply,
        },
    ];


    const {
        handleSubmit: handleAddEvidences,
        control: evidencesControl,
        formState: { errors: evidencesError },
        setValue: setEvidencesValue,
        getValues: getEvidencesValues,
        watch: evidencesWatch,
    } = useForm({
        defaultValues: {
            files: [],
            comments: '',
        }
    });

    const filesWatcher = evidencesWatch('files', [])

    const handleChangeFiles = (e) => {
        setEvidencesValue('files', Array.from(e.target.files))
    }

    const handleDeleteRow = (index) => {
        const newData = tableData.filter((_, i) => i !== index);
        setTableData(newData);
    };
    const newColumns = [
        {
            title: "Remitente",
            render: (rowData) => <span>{rowData.remitente}</span>,
        },
        {
            title: "Producto",
            render: (rowData) => <span>{rowData.producto}</span>,
        },
        {
            title: "Cantidad Total Declarada",
            render: (rowData) => <span>{rowData.cantidadTotalDeclarada}</span>,
        },
        {
            title: "Bodega de Origen",
            render: (rowData) => <span>{rowData.bodegaOrigen}</span>,
        },
        {
            title: "Tipo de Bodega Origen",
            render: (rowData) => <span>{rowData.tipoBodegaOrigen}</span>,
        },
        {
            title: "Bodega Destino",
            render: (rowData) => <span>{rowData.bodegaDestino}</span>,
        },
        {
            title: "Tipo de Bodega Destino",
            render: (rowData) => <span>{rowData.tipoBodegaDestino}</span>,
        },
        {
            title: "Acciones",
            render: (rowData) => (
                <button
                    style={{
                        backgroundColor: 'red',
                        border: 'none',
                        borderRadius: '50%',
                        padding: '10px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={() => handleDeleteRow(rowData.index)} 
                >
                    <FaRegTrashAlt style={{ color: 'white' }} />
                </button>
            ),
        },
    ];


    const handleConfirm = () => {
        const formData = getValues();

        if (!formData.warehouseOriginHub && selectedOriginHub) {
            setValue('warehouseOriginHub', selectedOriginHub);
        }

        const requiredFields = [
            { field: 'warehouseOrigin', message: 'La bodega de origen es requerida' },
            { field: 'warehouseOriginHub', message: 'El tipo de bodega origen es requerido' },
            { field: 'warehouseReceived', message: 'La bodega es requerida' },
            { field: 'warehouseReceivedHub', message: 'El tipo de bodega  es requerido' },
            { field: 'shipper', message: 'El remitente es requerido' },
            { field: 'product', message: 'El producto es requerido' },
            { field: 'totalDeclaredQty', message: 'La cantidad total declarada es requerida' }
        ];

        let hasError = false;

        requiredFields.forEach(({ field, message }) => {
            if (!formData[field]) {
                setValue(field, '', { shouldValidate: true });
                errors[field] = { message };
                hasError = true;
            } else {
                clearErrors(field);
            }
        });

        if (parseInt(formData['totalDeclaredQty']) <= 0) {
            setValue('totalDeclaredQty', '', { shouldValidate: true });
            errors['totalDeclaredQty'] = { message: 'La cantidad total declarada debe ser mayor a cero.' };
            hasError = true;
        } else {
            clearErrors('totalDeclaredQty');
        }

        if (hasError) {
            return;
        }

        const branchSelected = subHubsArr.find(hub => hub.id === selectedOriginHub?.value);
        const product = inventory.items.find((product) => product.idProduct == formData['product'].value );

        let validDeclaredQty = tableData.filter((data) => data.idProduct == formData['product'].value && data.idWarehouseOriginType == selectedOriginHub.value );
        validDeclaredQty = validDeclaredQty.reduce((acc, curr) => parseInt(acc) + parseInt(curr.cantidadTotalDeclarada), 0);

        if(!validDeclaredQty) validDeclaredQty = 0;

        validDeclaredQty = parseInt(validDeclaredQty) + parseInt(formData['totalDeclaredQty']);

        if(!product || product[branchSelected.stocktype] < validDeclaredQty) {
            Swal.fire({
                icon: 'error',
                title: 'Conflicto de Inventario',
                text: 'Revise los datos y asegúrese de que haya productos suficientes en el inventario.',
            });
            return;
        }

        const newRow = {
            bodegaOrigen: formData.warehouseOrigin.label,
            tipoBodegaOrigen: formData.warehouseOriginHub.label,
            bodegaDestino: formData.warehouseReceived.label,
            tipoBodegaDestino: formData.warehouseReceivedHub.label,
            remitente: formData.shipper.label,
            remitenteId: formData.shipper.value,
            idProduct: formData.product.value,
            idWarehouseOrigin: formData.warehouseOrigin.value,
            idWarehouseOriginType: formData.warehouseOriginHub.value,
            idWarehouseRecipient: formData.warehouseReceived.value,
            idWarehouseRecipientType: formData.warehouseReceivedHub.value,
            producto: formData.product.label,
            cantidadTotalDeclarada: formData.totalDeclaredQty,
        };
        setTableData([...tableData, newRow]);

        setValue('product', null);
        setValue('totalDeclaredQty', null);
      //  setValue('warehouseReceivedHub', null);
        // setSelectedOriginHub(null);
       // setSelectedReceivedHub(null);
    };

    const alertState = useSelector(state => state.alert);

    useEffect(() => {
        if (alertState && alertState.type) {
            Swal.fire({
                icon: alertState.type,
                title: alertState.title,
                text: alertState.message,
            });
        }
    }, [alertState]);

    useEffect(() => {
        if (inventory && inventory.items) {
            const branchSelected = subHubsArr.find(hub => hub.id === selectedOriginHub?.value);
    
            if (!branchSelected) {
                console.error('No se encontró un hub correspondiente para selectedOriginHub:', selectedOriginHub);
                return;
            }
    
            const subInventory = inventory.items
                .filter(product => parseInt(product[branchSelected.stocktype]) > 0) ;
            setAvailableInventory(subInventory);
        }        
    }, [inventory, selectedOriginHub]);

    useEffect(() => {
        if(warehouseOriginWatcher && warehouseReceivedWatcher && selectedOriginHub) {
            const subWarehouseTypesRepient = subHubsArr.filter(hub => {
                if (warehouseOriginWatcher?.value === warehouseReceivedWatcher?.value) {
                    return hub.id !== selectedOriginHub?.value;
                }
                return true;
            });
            setSubWarehouseTypesRepient(subWarehouseTypesRepient);
        }
    }, [warehouseOriginWatcher, warehouseReceivedWatcher, selectedOriginHub]);

    const handleSave = async () => {
        if (tableData.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Conflicto de Inventario',
                text: 'Revise los datos y asegúrese de que haya productos suficientes en el inventario.',
            });
            return;
        }
    
        const payload = {
            idWarehouseOrigin: tableData[0].idWarehouseOrigin,
            idWarehouseOriginType: tableData[0].idWarehouseOriginType,
            idWarehouseRecipient: tableData[0].idWarehouseRecipient,
            idWarehouseRecipientType: tableData[0].idWarehouseRecipientType,
            comments: comments,
            manifestDetail: tableData.map(row => ({
                idShipper: row.remitenteId,
                idProduct: row.idProduct,
                quantity: row.cantidadTotalDeclarada
            }))
        };
    
        try {
            await reduxTranslationWMSOrder(payload);
        } catch (error) {
            return error;

        }
    };
    return (
        <>
            <style>
                {`
                    .text-gray {
                        color: #6c757d;
                    }
                `}
            </style>
            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Topbar />
                        <div className="container-fluid">
                            <Header title={"Traslados"} actionButtons={actionButtons} />
                        </div>
                        <div className="card shadow mb-4 mx-3">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    Bodegas
                                </h6>
                            </div>
                            <div className="card-body d-flex flex-column flex-gap-1 p-3">

                                <div className='d-flex flex-wrap justify-content-between align-items-center mb-3'>
                                    <div className="d-flex flex-column mx-2">
                                        <label htmlFor="shipper" className="text-gray">Remitente</label>
                                        <Controller
                                            control={control}
                                            name="shipper"
                                            rules={{ required: 'El remitente es requerido' }}
                                            render={({ field }) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        aria-label="Remitente"
                                                        styles={customStyles}
                                                        options={
                                                            shippers && shippers?.items?.length > 0 && shippers.items
                                                                .filter(e => e.isActive === true && 
                                                                    (viewAllCountriesData || countryUserStr === null || e.country == countryUserStr))
                                                                .map(shipper => ({
                                                                    value: shipper.idCompany,
                                                                    label: shipper.description,
                                                                }))
                                                        }
                                                        onChange={(selectedOption) => {
                                                            setSelectedShipper(selectedOption);
                                                        }}
                                                        isDisabled={tableData.length > 0} 
                                                    />
                                                    {errors.shipper && <span className="error-message">{errors.shipper.message}</span>}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="d-flex flex-column mx-2">
                                        <label htmlFor="product" className="text-gray">Producto</label>
                                        <Controller
                                            control={control}
                                            name="product"
                                            rules={{ required: 'El producto es requerido' }}
                                            render={({ field }) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        aria-label="Producto"
                                                        styles={customStyles}
                                                        options={
                                                            selectedShipper && warehouseOriginWatcher && availableInventory
                                                                ? availableInventory
                                                                    .map(product => ({
                                                                        value: product.idProduct,
                                                                        label: product.name,
                                                                    }))
                                                                : []
                                                        }
                                                    />
                                                    {errors.product && <span className="error-message">{errors.product.message}</span>}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="d-flex flex-column mx-2" style={{ flex: 1 }}>
                                        <label htmlFor="totalDeclaredQty" className="text-gray">Cantidad Total Declarada</label>
                                        <Controller
                                            control={control}
                                            name="totalDeclaredQty"
                                            rules={{
                                                required: 'La cantidad total declarada es obligatoria y debe ser mayor a 0. Por favor, ingrese un valor válido en este campo.',
                                            }}
                                            render={({ field }) => (
                                                <>
                                                    <input
                                                        {...field}
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Cantidad Total Declarada"
                                                        aria-label="Cantidad Total Declarada"
                                                    />
                                                    {errors.totalDeclaredQty && <span className="error-message">{errors.totalDeclaredQty.message}</span>}
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center w-100'>
                                    <div className="d-flex flex-column mx-2 flex-grow-1">
                                        <label htmlFor="warehouseOrigin" className="text-gray">Bodega de Origen</label>
                                        <Controller
                                            control={control}
                                            name="warehouseOrigin"
                                            rules={{ required: 'La bodega de origen es requerida' }}
                                            render={({ field }) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        aria-label="Bodega de Origen"
                                                        styles={largerSelectStyles}
                                                        options={
                                                            warehouses && warehouses.items
                                                                ? warehouses.items
                                                                    .filter(e => e.isActive && (hasInventoryAdminPermission || e.warehouseHubs.some(hub => hub.idHub === userHub)))
                                                                    .map(ele => ({
                                                                        value: ele.idWarehouse,
                                                                        label: ele.warehouseHubs.length > 0 ? ele.warehouseHubs[0].hub.branchName : ele.name || ele.warehouseCode,
                                                                    }))
                                                                : []
                                                        }
                                                        placeholder="Seleccione una bodega"
                                                        // onChange={(selectedOption) => {
                                                        //     field.onChange(selectedOption);
                                                        // }}
                                                        isDisabled={tableData.length > 0} 
                                                    />
                                                    {errors.warehouseOrigin && <span className="error-message">{errors.warehouseOrigin.message}</span>}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="d-flex flex-column mx-2">
                                        <label htmlFor="warehouseOriginHub" className="text-gray">Tipo de Bodega Origen</label>
                                        <Controller
                                            control={control}
                                            name="warehouseOriginHub"
                                            rules={{ required: 'El tipo de bodega origen es requerido' }}
                                            render={({ field }) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        aria-label="Hub de Origen"
                                                        styles={customStyles}
                                                        value={field.value || null}
                                                        options={
                                                            hasInventoryAdminPermission
                                                                ? subHubsArr.map(hub => ({
                                                                    value: hub.id,
                                                                    label: hub.hub,
                                                                }))
                                                                : subHubsArr.filter(hub => hub.id === 1).map(hub => ({
                                                                    value: hub.id,
                                                                    label: hub.hub,
                                                                }))
                                                        }
                                                        placeholder="Seleccione un hub"
                                                        onChange={(selectedOption) => {
                                                            setSelectedOriginHub(selectedOption);
                                                            field.onChange(selectedOption);
                                                        }}
                                                        isDisabled={tableData.length > 0}
                                                    />
                                                    {errors.warehouseOriginHub && <span className="error-message">{errors.warehouseOriginHub.message}</span>}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className='d-flex align-items-center mt-4'>
                                        <TbArrowsLeftRight style={{ fontSize: '24px', margin: '0 10px' }} />
                                    </div>
                                    <div className="d-flex flex-column mx-2 flex-grow-1">
                                        <label htmlFor="warehouseReceived" className="text-gray">Bodega de Recibido</label>
                                        <Controller
                                            control={control}
                                            name="warehouseReceived"
                                            rules={{ required: 'La bodega de recibido es requerida' }}
                                            render={({ field }) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        aria-label="Bodega de Recibido"
                                                        styles={largerSelectStyles}
                                                        options={
                                                            warehouses && warehouses.items
                                                                ? warehouses.items
                                                                    .filter(e => e.isActive)
                                                                    .map(ele => ({
                                                                        value: ele.idWarehouse,
                                                                        label: ele.warehouseHubs.length > 0 ? ele.warehouseHubs[0].hub.branchName : ele.name || ele.warehouseCode,
                                                                    }))
                                                                : []
                                                        }
                                                        placeholder="Seleccione una bodega"
                                                        onChange={(selectedOption) => {
                                                            field.onChange(selectedOption);
                                                        }}
                                                        isDisabled={tableData.length > 0} 
                                                    />
                                                    {errors.warehouseReceived && <span className="error-message">{errors.warehouseReceived.message}</span>}
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className="d-flex flex-column mx-2">
                                        <label htmlFor="warehouseReceivedHub" className="text-gray">Tipo de Bodega Recibido</label>
                                        <Controller
                                            control={control}
                                            name="warehouseReceivedHub"
                                            rules={{ required: 'El tipo de bodega destino es requerido' }}
                                            render={({ field }) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        aria-label="Tipo de Bodega Recibido"
                                                        styles={customStyles}
                                                        options={subWarehouseTypesRepient
                                                            .map(hub => ({
                                                                value: hub.id,
                                                                label: hub.hub,
                                                            }))}
                                                        placeholder="Seleccione un hub"
                                                        onChange={(selectedOption) => {
                                                            setSelectedReceivedHub(selectedOption);
                                                            field.onChange(selectedOption);
                                                        }}
                                                        isDisabled={tableData.length > 0} 
                                                    />
                                                    {errors.warehouseReceivedHub && <span className="error-message">{errors.warehouseReceivedHub.message}</span>}
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>


                                <div className='d-flex justify-content-center mt-3'>
                                    <Button
                                        variant='primary'
                                        type='button'
                                        onClick={handleConfirm}>
                                        Confirmar
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="card shadow mb-4 mx-3">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    Resumen
                                </h6>
                            </div>
                            <div className="card-body">
                                <Grid
                                    showFilters={false}
                                    showRange={false}
                                    cols={newColumns}
                                    data={tableData.map((row, index) => ({ ...row, index }))} 
                                />

                                <div className="form-group mt-3">
                                    <Controller
                                        control={evidencesControl}
                                        name="files"
                                        render={() => (
                                            <>
                                                <label
                                                    htmlFor='files'
                                                    className='form-label'>
                                                    Evidencias
                                                </label>
                                                <MultiFile
                                                    files={filesWatcher}
                                                    accept="image/*"
                                                    onChange={handleChangeFiles}
                                                />
                                                {
                                                    evidencesError.files &&
                                                    <span className="error-message">
                                                        {evidencesError.files.message}
                                                    </span>
                                                }
                                            </>
                                        )}
                                    />
                                </div>
                                <div className="form-group mt-3">
                                    <label htmlFor="observations">Comentarios/Observaciones</label>
                                    <textarea 
                                        className="form-control" 
                                        id="observations" 
                                        rows="3"
                                        value={comments}
                                        onChange={(e) => setComments(e.target.value)}
                                    ></textarea>
                                </div>

                                <div className='d-flex justify-content-center mt-3'>
                                    <Button
                                        variant='primary'
                                        type='button'
                                        onClick={handleSave}>
                                        Guardar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        shippers: state.companyState.clients,
        warehouses: state.warehouseState.warehouses,
        products: state.productState.products,
        errorTranslation: state.warehouseState.translationWMSOrderResultError,
        successfulTranslation: state.warehouseState.translationWMSOrderResultSuccess,
        inventory: state.inventoryState.inventory,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetInventory: (payload) =>
            dispatch({
                type: "READ_INVENTORY_REQUEST",
                value: payload,
        }),
        reduxGetShippers: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
                value: payload,
            }),
        reduxGetWarehouses: (payload) =>
            dispatch({
                type: "FETCH_WAREHOUSES_REQUEST",
                value: payload,
            }),
        reduxGetProducts: (payload) =>
            dispatch({
                type: "FETCH_PRODUCTS_REQUEST",
                value: payload,
            }),
        reduxTranslationWMSOrder: (payload) =>
            dispatch({
                type: "TRANSLATION_WMSORDER_REQUEST",
                value: payload,
            }),
        downloadManifest: (idManifest) =>
                dispatch({
                    type: "DOWNLOAD_MANIFEST_REQUEST",
                    payload: { idManifest },
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RelocationWms);
