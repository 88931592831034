import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../../middlewares/auth";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useAuth } from "../../../hooks/useAuth";
import { Button, Modal } from "react-bootstrap";
import NetworkQualityIndicator from "../../Common/netWorkIndicator";
import NotificationBell from "../../Common/NotificationBell";

const Swal = require("sweetalert2");

const Topbar = ({ changePassword, successful, error}) => {
  const history = useHistory();
  const { session, signOut } = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();


  const [show, setShow] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);

  const onLogout = () => {
    logout();
    signOut();
    history.push("/");
  };

  const onChangePassword = () => {
    setIsEditingPassword(true);
    reset();
    setShow(true);
  };

  const handleClose = () => {
    setIsEditingPassword(false);
    reset();
    setShow(false);
  };

  const onSubmit = (data) => {
    changePassword({ ...data, idUser: session.idUser });
  };
  
  useEffect(() => {
    if (isEditingPassword && successful == true) {
      reset();
      logout();
      signOut();
      Swal.fire(
        "Proceso exitoso!",
        "Contraseña modificada... por favor loguearse de nuevo.",
        "success"
      );
      history.push("/");
    }
  }, [successful]);

  useEffect(() => {
    if (isEditingPassword && error == true) {
      Swal.fire("Falló!", "La contraseña no puede ser cambiada.", "error");
    }
  }, [error]);

  return (
    <>
      <nav className="navbar navbar-expand navbar-light bg-gray-100 topbar mb-0 static-top py-0" style={{ maxHeight: '55px', minHeight: '55px' }}>
      {/* <!-- Topbar Navbar --> */}
        {/* <div className="d-flex justify-content-end">
            <NetworkQualityIndicator/>
        </div> */}
      
        {/* <ul className="navbar-nav ml-auto"> */}
          {/* <!-- Nav Item - User Information --> */}
          {/* <NotificationBell />
          <div className="topbar-divider d-none d-sm-block"></div> */}
          {/* <li className="nav-item dropdown no-arrow"> */}
            {/* <a
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                {session && `${session.firstName} ${session.lastName}`}
              </span>
              <img
                className="img-profile rounded-circle"
                src="https://startbootstrap.github.io/startbootstrap-sb-admin-2/img/undraw_profile.svg"
              />
            </a> */}
            {/* <!-- Dropdown - User Information --> */}
            {/* <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <Link
                className="dropdown-item"
                href="#"
                onClick={onLogout}
                to="/#"
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
              </Link>
              <Link
                className="dropdown-item"
                href="#"
                onClick={onChangePassword}
                to="/#"
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Cambiar contraseña
              </Link>
            </div> */}
          {/* </li>
        </ul> */}
      </nav>
      {/* <div>
        <Modal
          show={show}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>Cambiar contraseña</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label htmlFor="newPassword" className="form-label">
                  Nueva contraseña
                </label>
                <input
                  id="newPassword"
                  type="password"
                  className={`form-control form-control-user ${
                    errors.newPassword && "is-invalid"
                  }`}
                  {...register("newPassword", { required: true })}
                />
                {errors.newPassword && (
                  <span className="invalid-feedback">
                    La contraseña es requerida
                  </span>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    successful: state.changePasswordState.successful,
    error: state.changePasswordState.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (data) =>
      dispatch({
        type: "CHANGEPASSWORD_REQUEST",
        data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
