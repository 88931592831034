import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../utils/request";

function* getAllPreOrders(payload) {
  try {
    yield put({
      type: "FETCH_PRE_ORDER_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const { page, offset, from, to, idShipper, idOperator, search } = payload.value;

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/preOrder?page=${page}&offset=${offset}&from=${from}&to=${to}&idShipper=${idShipper}&idOperator=${idOperator}&search=${search}`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_PRE_ORDER_SUCCESS",
      value: response,
    });
  } catch (error) {
    console.log(error);
    console.log(error);
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load pre-orders",
      },
    });
    yield put({
      type: "FETCH_PRE_ORDER_ERROR",
    });
  }
}

function* createPreOrder(payload) {
  try {
    yield put({ type: "CREATE_PRE_ORDER_REQUESTING" });
    yield put({
      type: "SHOW_LOADING",
    });
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/preOrder/preOrderFromAdmin`;
    
    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload.value),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Successful save",
        message: "Successful save order",
      },
    });

    yield put({
      type: "CREATE_PRE_ORDER_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed save",
        message: "Failed save order",
      },
    });
    yield put({
      type: "CREATE_PRE_ORDER_ERROR",
    });
  } finally {
    yield put({
      type: "CREATE_PRE_ORDER_DONE",
    });
  }
}

export function* watchPreOrders() {
  yield takeLatest("FETCH_PRE_ORDER_REQUEST", getAllPreOrders);
  yield takeLatest("FETCH_CREATE_PRE_ORDER", createPreOrder);
}
