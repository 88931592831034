import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { buttonIcon, buttonTypes } from "../../../utils/buttonsUtil";
import { useHistory } from "react-router";
import { Tabs, Tab } from "react-bootstrap";
import Sidebar from "../../../components/Navigation/Sidebar"
import Topbar from "../../../components/Navigation/Topbar";
import Header from "../../../components/Page/header";
import { PermissionContext } from '../../../hooks/PermissionContext';
import AdjustmentsHistoryList from '../AdjustmentHistoryList/index';
import AdjustmentPendingList from '../AdjustmentPendingList/index'

const AdjustmentsHandler = ({ }) => {

    const history = useHistory();
    const { hasPermission } = useContext(PermissionContext);
    
    const [activeTab, setActiveTab] = useState('tab1');

    const actionButtons = [
        {
            onClick: () => history.push("/wms/inventory-movements"),
            description: "Regresar a movimientos de inventario",
            buttonType: buttonTypes.Primary,
            buttonIcon: buttonIcon.Arrow_LeftReply,
        },
        {
            permission: 'feature:create-wms-adjustments',
            onClick: () => history.push("/inventory/new-inventory-adjustment"),
            description: "Crear ajuste de inventario",
            buttonType: buttonTypes.Success,
            buttonIcon: buttonIcon.Add,
        },
    ];

    const ChangeTab = (tab) => {
        setActiveTab(tab);
    }

    return (
        <>
            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <Topbar />
                    <div className="container-fluid">
                        <Header title={"Ajustes de inventario"} subtitle={"Módulo para la gestión de los ajustes de inventario"} actionButtons={actionButtons} />
                        <Tabs activeKey={activeTab} onSelect={ChangeTab} >
                            <Tab eventKey="tab1" title="Ajustes pendientes">
                                <AdjustmentPendingList />
                            </Tab>
                            <Tab eventKey="tab2" title="Histórico de ajustes">
                                <AdjustmentsHistoryList />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdjustmentsHandler);