import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import Grid from '../../../components/Grid';
import { buttonIcon, buttonTypes } from "../../../utils/buttonsUtil";
import { Show } from "../../../hooks/Show";
import Swal from "sweetalert2";
import { adjustmentMovementsTypes, adjustmentMovementsSubtypes } from "../../../utils/enumsUtil"
import DetailAdjustmentModal from "../../../components/Modal/WMS/Inventory/Adjustments/detailProducts/index";

const AdjustmentPendingList = ({
    reduxGetPendingAdjustmentList,
    pendingAdjustmentList,
    successAdjustmentDecision,
    AdjustmentDecision,
    reduxAdjustmentDecision,
    reduxGetHistoricalAdjustmentList,
}) => {

    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [showModalDetails, setShowModalDetails] = useState(null);
    const [selectedAjustment, setSelectedAjustment] = useState(null);
    const [selectedMovementTypeName, setSelectedMovementTypeName] = useState(null);

    useEffect(() => {
        reduxGetPendingAdjustmentList({
            page: currentPage,
            offset: offset,
            search: search,
        });
    }, []);

    useEffect(() => {
        if (successAdjustmentDecision) {
            reduxGetPendingAdjustmentList({
                page: 1,
                offset: 10,
                search: "",
            });

            reduxGetHistoricalAdjustmentList({
                page: 1,
                offset: 10,
                search: "",
            });
        }
    }, [successAdjustmentDecision, AdjustmentDecision]);

    const tableHeaders = [
        "idAjuste",
        "tipo de ajuste",
        "Bodega",
        "Producto",
        "Cantidad del ajuste",
        "Creado por",
    ];

    const columns = [
        {
            title: "Bodega",
            field: 'warehouseName',
            searchable: true,
            render: (rowData) => {
                return <span>{rowData?.details[0]?.inventory.warehouse.name}</span>;
            }
        },
        {
            title: "Tipo de ajuste",
            field: 'adjustmentSubtype',
            searchable: true,
            render: (rowData) => {
                const movementTypeName = adjustmentMovementsSubtypes.find(
                    (el) => el.code == rowData?.idAdjustmentSubType
                )?.label;

                return <span>{movementTypeName}</span>;
            },
        },
        {
            title: "Creado por",
            render: (rowData) => {
                const { firstName, lastName } = rowData.requestingUser
                return <span>{`${firstName} ${lastName}`}</span>;
            },
        },
        {
            title: "Total del ajuste",
            render: (rowData) => {
                const totalAdjustment = rowData.details.reduce((total, detail) => {
                    return total + (detail.quantity || 0);
                }, 0);

                return <span>{`${totalAdjustment}`}</span>;
            },
        },
        {
            title: "Fecha de creación",
            render: (rowData) => {
                const createdDate = rowData?.createdDate;
                const formattedDate = createdDate
                    ? new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(createdDate))
                    : "N/A";
            
                return <span>{formattedDate}</span>;
            }
        },
        {
            title: "Acciones",
            render: (rowData) => {
                return (
                    <>
                        <button
                            title="Ver detalle"
                            className={`btn btn-sm btn-circle mr-2 ${buttonTypes.Primary}`}
                            type="button"
                            onClick={() => handleClickShowDetailsAdjustment(rowData)}
                        >
                            <i className={buttonIcon.Eye}></i>
                        </button>
                        {/* permiso de coordinador */}
                        <Show when="feature:process-wms-adjustments">
                            <button
                                title="Aprobar"
                                className={`btn btn-sm btn-circle mr-2 ${buttonTypes.Success}`}
                                type="button"
                                onClick={() => handleClickApprovedAdjustment(rowData)}
                            >
                                <i className={buttonIcon.Check}></i>
                            </button>
                            <button
                                title="Denegar"
                                className={`btn btn-sm btn-circle mr-2 ${buttonTypes.Danger}`}
                                type="button"
                                onClick={() => handleClickDenyAdjustment(rowData)}
                            >
                                <i className={buttonIcon.Xmark}></i>
                            </button>
                        </Show>
                    </>
                );
            },
        },
    ];

    const handleClickShowDetailsAdjustment = (rowData) => {
        const selectedMovementTypeName = adjustmentMovementsSubtypes.find((el) => el.code == rowData?.idAdjustmentSubType)?.label;

        setSelectedAjustment(rowData);
        setSelectedMovementTypeName(selectedMovementTypeName);
        setShowModalDetails(true);
    }

    const handleClickApprovedAdjustment = (rowData) => {
        Swal.fire({
            title: 'Confirmación',
            text: '¿Está seguro de aprobar el ajuste de inventario?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#21b5ea',
            cancelButtonColor: '#858796',
            confirmButtonText: 'Aceptar',
        }).then((result) => {
            if (result.isConfirmed) {
                reduxAdjustmentDecision({
                    idAdjustment: rowData.idAdjustment,
                    idAdjustmentSubType: rowData.idAdjustmentSubType,
                    approvalDecision: 2
                });

                if (successAdjustmentDecision) {
                    reduxGetPendingAdjustmentList({
                        page: 1,
                        offset: 10,
                        search: "",
                    });
                }
            };
        });
    };

    const handleCloseModal = () => { setShowModalDetails(false) }

    const handleClickDenyAdjustment = (rowData) => {
        Swal.fire({
            title: 'Confirmación',
            text: '¿Está seguro de rechazar el ajuste de inventario?',
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#21b5ea',
            cancelButtonColor: '#858796',
            confirmButtonText: 'Aceptar',
        }).then((result) => {
            if (result.isConfirmed) {
                reduxAdjustmentDecision({
                    idAdjustment: rowData.idAdjustment,
                    approvalDecision: 3
                });

                reduxGetPendingAdjustmentList({
                    page: 1,
                    offset: 10,
                    search: "",
                });
            };
        });
    };

    const getSearchQuery = (searchValue) => { return searchValue }

    return (
        <>
            <div className='card shadow mb-4'>
                <div className='card-body'>
                    <Grid
                        cols={columns}
                        data={
                            pendingAdjustmentList && Object.keys(pendingAdjustmentList).length > 0
                                ? pendingAdjustmentList.items
                                : []
                        }
                        page={
                            pendingAdjustmentList && Object.keys(pendingAdjustmentList).length > 0
                                ? Number(pendingAdjustmentList.page)
                                : 1
                        }
                        pages={
                            pendingAdjustmentList && Object.keys(pendingAdjustmentList).length > 0
                                ? Number(pendingAdjustmentList.totalPages)
                                : 0
                        }
                        total={
                            pendingAdjustmentList && pendingAdjustmentList.hasOwnProperty("total") ? pendingAdjustmentList.total : 0
                        }
                        offset={offset}
                        onChangePage={(page) => {
                            setCurrentPage(page);
                            reduxGetPendingAdjustmentList({
                                page: page,
                                offset: offset,
                                search: search,
                            });
                        }}
                        onChangeRange={(value) => {
                            setOffset(value);
                            reduxGetPendingAdjustmentList({
                                page: 1,
                                offset: value,
                                search: search,
                            });
                        }}
                        defaultValue={search}
                        onChangeSearch={(value) => {
                            setSearch(value);
                            reduxGetPendingAdjustmentList({
                                page: 1,
                                offset: offset,
                                search: getSearchQuery(value.trim()),
                            });
                        }}
                    />
                </div>
            </div>
            <DetailAdjustmentModal
                selectedAjustment={selectedAjustment}
                selectedMovementTypeName={selectedMovementTypeName}
                showModalDetails={showModalDetails}
                handleCloseModal={handleCloseModal}
            ></DetailAdjustmentModal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        pendingAdjustmentList: state.adjustmentState.pendingAdjustmentList,
        successAdjustmentDecision: state.adjustmentState.successAdjustmentDecision,
        AdjustmentDecision: state.adjustmentState.AdjustmentDecision,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetPendingAdjustmentList: (payload) =>
            dispatch({
                type: "FETCH_GET_PENDING_ADJUSTMENTS",
                value: payload,
            }),
        reduxAdjustmentDecision: (payload) =>
            dispatch({
                type: "ADJUSTMENT_DECISION",
                value: payload,
            }),
        reduxGetHistoricalAdjustmentList: (payload) =>
            dispatch({
                type: "FETCH_GET_HISTORICAL_ADJUSTMENTS",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdjustmentPendingList);
