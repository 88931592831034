const initialState = {
  requestingFetchPreOrders: false,
  successfulFetchPreOrders: false,
  errorFetchPreOrders: false,
  preOrders: {},
  requestingCreatePreOrders: false,
  successfulCreatePreOrders: false,
  errorCreatePreOrders: false,
  dataCreatePreOrder: {},
};

const preOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PRE_ORDER_REQUESTING":
      return {
        ...state,
        requestingFetchPreOrders: true,
        successfulFetchPreOrders: false,
        errorFetchPreOrders: false,
      };
    case "FETCH_PRE_ORDER_SUCCESS":
      return {
        ...state,
        requestingFetchPreOrders: false,
        successfulFetchPreOrders: true,
        errorFetchPreOrders: false,
        preOrders: action.value,
      };
    case "FETCH_PRE_ORDER_ERROR":
      return {
        ...state,
        requestingFetchPreOrders: false,
        successfulFetchPreOrders: false,
        errorFetchPreOrders: true,
      };
    case "CREATE_PRE_ORDER_REQUESTING":
      return {
        ...state,
        requestingCreatePreOrders: true,
        successfulCreatePreOrders: false,
        errorCreatePreOrders: false,
      };
    case "CREATE_PRE_ORDER_SUCCESS":
      return {
        ...state,
        requestingCreatePreOrders: false,
        successfulCreatePreOrders: true,
        errorCreatePreOrders: false,
        dataCreatePreOrder: action.value,
      };
    case "CREATE_PRE_ORDER_ERROR":
      return {
        ...state,
        requestingCreatePreOrders: false,
        successfulCreatePreOrders: false,
        errorCreatePreOrders: true,
      };
    case "CREATE_PRE_ORDER_DONE":
      return {
        ...state,
        requestingCreatePreOrders: false,
        successfulCreatePreOrders: false,
        errorCreatePreOrders: false,
        dataCreatePreOrder: {},
      };
    case "RESET_PRE_ORDER_FORM":
      return initialState;
    default:
      return state;
  }
};

export default preOrderReducer;
