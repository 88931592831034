import * as moment from 'moment';
import Swal from "sweetalert2";
import { HideDataForRolesEnum } from './enumsUtil';
import Holidays from 'date-holidays';

export const LabelByState = (state) => {
  switch (state) {
    case -1:
      return <span className="badge badge-pill badge-danger">Falló</span>;
    case 1:
      return <span className="badge badge-pill badge-success">Procesada</span>;
    case 2:
      return (
        <span className="badge badge-pill badge-success">
          Asignada a un operador
        </span>
      );
    case 3:
      return <span className="badge badge-pill badge-warning">Sin guía</span>;
    case 4:
      return (
        <span className="badge badge-pill badge-light">
          En tránsito a ciudad destino
        </span>
      );
    case 5:
      return (
        <span className="badge badge-pill badge-light">
          Asignada a un mensajero
        </span>
      );
    case 6:
      return (
        <span className="badge badge-pill badge-light">
          En ruta / distribución
        </span>
      );
    case 7:
      return <span className="badge badge-pill badge-light">Entregado</span>;
    case 8:
      return <span className="badge badge-pill badge-light">No entregado</span>;
    case 9:
      return (
        <span className="badge badge-pill badge-light">
          Devolucion en transito
        </span>
      );
    case 10:
      return (
        <span className="badge badge-pill badge-light">
          Devuelto al remitente
        </span>
      );
    case 11:
      return <span className="badge badge-pill badge-light">Alistamiento</span>;
    case 12:
      return <span className="badge badge-pill badge-light">Telemercadeo</span>;
    case 13:
      return <span className="badge badge-pill badge-light">Incautado</span>;
    case 14:
      return (
        <span className="badge badge-pill badge-light">
          Para reclamar en sucursal
        </span>
      );
    case 15:
      return (
        <span className="badge badge-pill badge-light">Producto Perdido</span>
      );
    case 16:
      return (
        <span className="badge badge-pill badge-light">Producto Dañado</span>
      );
    case 17:
      return (
        <span className="badge badge-pill badge-secondary">
          Centro de acopio
        </span>
      );
    case 18:
      return <span className="badge badge-pill badge-secondary">Packing</span>;
    case 19:
      return <span className="badge badge-pill badge-danger">Anulado</span>;
    case 20:
      return <span className="badge badge-pill badge-danger">Destruccion</span>;
    case 21:
      return <span className="badge badge-pill badge-danger">Investigacion</span>;
    default:
      break;
  }
};

export const onlyLetters = (word, regex = null) => {
  if (word !== "" && word !== undefined) {
    if (regex) {
      return word.replace(regex, "");  
    }
    return word.replace(/[^A-Za-z ]/g, "");
  }
}

export const capitalizeFirstLetter = (word) => {
  if (word !== "" && word !== undefined) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
}

export const createWordFromPhrase = (texto, limite) => {
  if (texto !== "" && texto !== undefined) {
    let palabras = texto.split(' ');
    let abreviacion = '';

    if (palabras.length === 1) {
      abreviacion = palabras[0].slice(0, limite);
    } else if (palabras.length === 2) {
      const firstTwoLetters = palabras[0].slice(0, (limite/2));
      const secondTwoLetters = palabras[1].slice(0, (limite/2));
      abreviacion = firstTwoLetters + secondTwoLetters;
    } else {
      for (let palabra of palabras) {
        abreviacion += palabra.charAt(0).toUpperCase();
        if (abreviacion.length >= limite) {
          break;
        }
      }
    }

    return abreviacion.toUpperCase()
  }
}

export const isHoliday = (date, country) => {
  if(isNullOrUndefined(country)) return false;

  const hd = new Holidays(country);
  const holidays = hd.getHolidays(date.getFullYear());
  const holidayDates = holidays.map(holiday =>
    new Date(Date.UTC(
      holiday.date.year,
      holiday.date.month - 1, // Meses en JavaScript comienzan en 0
      holiday.date.day
    ))
  );

  // Validar fecha seleccionada
    const dateWithoutTime = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return holidayDates.some(holiday =>
      holiday.getTime() === dateWithoutTime.getTime()
    )
    
}

export const isWeekend = (date) => {
  const day = date.getDay();
  return day === 0 || day === 6;
}

export const isBusinessDay = (date, country) => {
  return !isWeekend(date) && !isHoliday(date, country);
}

export const getBusinessDate = (startDate, daysToAdd, country) => {
  const endDate = new Date(startDate);
  let addedDays = 0;

  while (addedDays < daysToAdd) {
    endDate.setDate(endDate.getDate() + 1); // Add a day
    if (isBusinessDay(endDate, country)) addedDays++;
  }
  
  return endDate;
}

export const isNullOrUndefined = (field) => {
  return (
    field === undefined ||
    field === 'undefined' ||
    field === null ||
    field === '' ||
    field === 'Invalid date'
  );
};

export const getDaysDiff = (date1, date2) => {
  if(isNullOrUndefined(date1) || isNullOrUndefined(date2))
    return null;

  const _date1 = moment(date1);
  const _date2 = moment(date2);

  return _date1.diff(_date2, 'days');
}

export const exportCSV = (data, fileName) => {
  const token = window.localStorage.getItem('token');
  const body = { fileName, data }

  fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/download/csv`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.blob())
    .then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
}

export const isValidCSV = (data, minColumns, maxColumns) => {
  if (data && data.trim().length === 0) {
    Swal.fire(
      'Archivo errado',
      'Señor Usuario, el archivo parece no tener datos. Por favor revíselo.',
      'error');
    return false;
  }

  const regexPattern = /(?:("[^"]*"|[^;]+);)*?(?:"[^"]*"|[^;]+)/g;

  var filas = data.split('\r\n');
  filas = filas.filter(e => !isNullOrUndefined(e));

  for (var i = 0; i < filas.length; i++) {
    // Verificar el número de columnas en cada fila
    var rowColumns = filas[i].match(regexPattern);
    var columnsFound = rowColumns ? rowColumns.length : 0;

    if (columnsFound < minColumns) {
      Swal.fire(
        'Archivo errado',
        `Señor Usuario, el archivo cargado contiene menos de ${minColumns} columnas. Por favor revise el archivo.`,
        'error');
      return false;
    }
    if (columnsFound > maxColumns) {
      Swal.fire(
        'Archivo errado',
        `Señor Usuario, el archivo cargado contiene más de ${maxColumns} columnas. Por favor revise el archivo.`,
        'error');
      return false;
    }
  }

  if (data.file && data.file.type !== 'text/csv') {
    Swal.fire(
      'Archivo errado',
      'El archivo no cumple con el formato establecido <br/> Señor usuario el archivo debe tener el formato \'CSV UTF-8 delimitado por comas\'.',
      'error');
    return false;
  }

  return true;
}

export const HideByRole = (userRole) => {
  return Object.values(HideDataForRolesEnum).includes(userRole);
}

export const hasNoCountryLimitations = (userData) => {  
  if (isNullOrUndefined(userData.role) || isNullOrUndefined(userData.allowAllCountries)) {
    return false;
  }

  const { allowAllCountries, role } = userData;
  return (role.description === "PROVIDERS MANAGER" && allowAllCountries)
}