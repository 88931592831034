import { call, put, takeLatest } from "redux-saga/effects";
import request, { requestAxios } from "../../utils/request";
import Papa from 'papaparse';  

function* fetchIncomings(payload) {
  try {
    yield put({
      type: "FETCH_WMS_INCOMINGS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/inventory/incomings?page=${payload.value.page ?? ''}&offset=${payload.value.offset ?? ''}`;

    if (payload.value.shipper)
      requestURL += `&shipper=${payload.value.shipper}`;

    if (payload.value.warehouses)
      requestURL += `&warehouses=${payload.value.warehouses}`;

    if (payload.value.products)
      requestURL += `&products=${payload.value.products}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_WMS_INCOMINGS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed fetch receivings",
        message: "Failed fetch receivings",
      },
    });
    yield put({
      type: "FETCH_WMS_INCOMINGS_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* createManifest(payload) {
  try {
    yield put({
      type: "CREATE_MANIFEST_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/inventory/incomingStockMovement`;

    const { files, ...data } = payload.value;

    const formData = new FormData();

    formData.append('idShipper', data.idShipper);
    formData.append('idWarehouse', data.idWarehouse);
    formData.append('manifestType', data.manifestType);
    formData.append('declaredQty', data.declaredQty);

    if(data.idAddress)
      formData.append(`idAddress`, data.idAddress);

    if(data.idTransaction)
      formData.append(`idTransaction`, data.idTransaction);

    if(data.idWarehouseRecipient)
      formData.append(`idWarehouseRecipient`, data.idWarehouseRecipient);

    data.manifestDetail.forEach((item, index) => {
      formData.append(`manifestDetail[${index}].product.idProduct`, item.product.idProduct);
      formData.append(`manifestDetail[${index}].available`, item.available);
      formData.append(`manifestDetail[${index}].damaged`, item.damaged);
      formData.append(`manifestDetail[${index}].missing`, item.missing);
      formData.append(`manifestDetail[${index}].extra`, item.extra);
      formData.append(`manifestDetail[${index}].expired`, item.expired);
      formData.append(`manifestDetail[${index}].supplanted`, item.supplanted);
      formData.append(`manifestDetail[${index}].externalOrder`, item.externalOrder);
      if(item.lot) {
        formData.append(`manifestDetail[${index}].lot.number`, item.lot.number);
        formData.append(`manifestDetail[${index}].lot.serial`, item.lot.serial);
        formData.append(`manifestDetail[${index}].lot.dateExp`, item.lot.dateExp);
      }
  });

    files.forEach((image, index) => {
      formData.append(`files[${index}]`, image);
    });

    formData.append('comments', data.comments);

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: formData,
    };

    const response = yield call(requestAxios, requestURL, headers);

    yield put({
      type: "CREATE_MANIFEST_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed fetch receivings",
        message: "Failed fetch receivings",
      },
    });
    yield put({
      type: "CREATE_MANIFEST_ERROR",
      value: error.response
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* getOrderForReturn(payload) {
  try {
    yield put({
      type: "FETCH_WMS_ORDER_FOR_RETURN_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/order/forReturn/${payload.value.trackingOrReturn}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_WMS_ORDER_FOR_RETURN_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "FETCH_WMS_ORDER_FOR_RETURN_ERROR",
    });
  }
}

function* getOrderForRelocation(payload) {
  try {
    yield put({
      type: "FETCH_WMS_ORDER_FOR_RELOCATION_REQUESTING",
    });
    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/manifest/find-manifest/${payload.value.trackingOrReturn}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "HIDE_LOADING",
    });

    yield put({
      type: "FETCH_WMS_ORDER_FOR_RELOCATION_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "FETCH_WMS_ORDER_FOR_RELOCATION_ERROR",
    });
  }
}

function* fetchReturns(payload) {
  try {
    yield put({
      type: "FETCH_WMS_RETURNS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/returns?page=${payload.value.page ?? ''}&offset=${payload.value.offset ?? ''}`;

    if (payload.value.search)
      requestURL += `&search=${payload.value.search}`;

    if (payload.value.idOperator)
      requestURL += `&idOperator=${payload.value.idOperator}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_WMS_RETURNS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Error al obtener devoluciones",
        message: "Error al obtener las devoluciones",
      },
    });
    yield put({
      type: "FETCH_WMS_RETURNS_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* createWMSReturn(payload) {
  try {
    yield put({ type: "CREATE_WMS_RETURN_REQUESTING" });
    yield put({ type: "SHOW_LOADING" });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/returns`;
    
    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload.value),
    };

    const response = yield call(requestAxios, requestURL, headers);
    yield put({ type: "CREATE_WMS_RETURN_SUCCESS", value: response });
  } catch (error) {
    yield put({ type: "CREATE_WMS_RETURN_ERROR", value: error.response || error });
  } finally {
    yield put({ type: "HIDE_LOADING" });
  }
}

function* downloadReturnCertificate(payload) {
  try {
    yield put({ type: "DOWNLOAD_RETURN_CERTIFICATE_REQUESTING" });
    yield put({ type: "SHOW_LOADING" });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/returns/downloadCertificate/${payload.value}`;
    
    // Crear una promesa para manejar XMLHttpRequest
    const downloadFile = () => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', requestURL, true);
        xhr.setRequestHeader('Authorization', `Bearer ${window.localStorage.getItem("token")}`);
        xhr.responseType = 'blob';

        xhr.onload = function() {
          if (this.status === 200) {
            resolve(this.response);
          } else {
            reject(new Error('Failed to download file'));
          }
        };

        xhr.onerror = function() {
          reject(new Error('Network error'));
        };

        xhr.send();
      });
    };

    const blob = yield call(downloadFile);
    
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `certificado-devolucion-${payload.value}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    yield put({ type: "DOWNLOAD_RETURN_CERTIFICATE_SUCCESS" });

  } catch (error) {
    console.error('Error al descargar:', error);
    yield put({ type: "DOWNLOAD_RETURN_CERTIFICATE_ERROR" });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Error",
        message: "Error al descargar el certificado de devolución",
      },
    });
  } finally {
    yield put({ type: "HIDE_LOADING" });
  }
}

function* fetchMovements(payload) {
  try {
    yield put({
      type: "FETCH_WMS_MOVEMENTS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/inventory/movements?page=${payload.value.page ?? ''}&offset=${payload.value.offset ?? ''}`;

    if (payload.value.shipper)
      requestURL += `&shipper=${payload.value.shipper}`;

    if (payload.value.warehouses)
      requestURL += `&warehouses=${payload.value.warehouses}`;

    if (payload.value.products)
      requestURL += `&products=${payload.value.products}`;

    if (payload.value.movementType) {
      requestURL += `&movementType=${payload.value.movementType}`;
    }

    if(payload.value.startDate && payload.value.endDate){
      requestURL += `&startDate=${payload.value.startDate}&endDate=${payload.value.endDate}`;
    }

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "FETCH_WMS_MOVEMENTS_SUCCESS",
      value: response,
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed fetch receivings",
        message: "Failed fetch receivings",
      },
    });
    yield put({
      type: "FETCH_WMS_MOVEMENTS_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* downloadMovements(payload) {
  try {
    yield put({
      type: "DOWNLOAD_WMS_MOVEMENTS_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });
console.log(payload)
    let requestURL = `${process.env.REACT_APP_API_URL}/api/v1/warehouse/inventory/downloadMovements?shipper=${payload.value.shipper ?? ''}&warehouses=${payload.value.warehouses ?? ''}&products=${payload.value.products ?? ''}&movementType=${payload.value.movementType ?? ''}&startDate=${payload.value.startDate ?? ''}&endDate=${payload.value.endDate ?? ''}`;

    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(fetch, requestURL.trim(), headers);

    const blob = yield call([response, 'blob']);

    var fileName='movements-report_' + Date.now() + '.csv';

    const url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();

    yield put({
      type: "DOWNLOAD_WMS_MOVEMENTS_SUCCESS",
      payload: { url, fileName }
    });
  } catch (error) {
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed fetch receivings",
        message: "Failed fetch receivings",
      },
    });
    yield put({
      type: "DOWNLOAD_WMS_MOVEMENTS_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* processReturn(payload) {
  try {
    yield put({ type: "PROCESS_WMS_RETURN_REQUESTING" });
    yield put({ type: "SHOW_LOADING" });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/returns/${payload.value}`;
    
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "PROCESS_WMS_RETURN_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "PROCESS_WMS_RETURN_ERROR",
      value: error.response?.data || error,
    });
  } finally {
    yield put({ type: "HIDE_LOADING" });
  }
}

function* processReturnOrder(payload) {
  try {
    yield put({ type: "PROCESS_WMS_RETURN_ORDER_REQUESTING" });
    yield put({ type: "SHOW_LOADING" });

    const { idReturn, trackingOrReturn } = payload.value;
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/returns/${idReturn}/order/${trackingOrReturn}`;
    
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };

    const response = yield call(request, requestURL, headers);

    yield put({
      type: "PROCESS_WMS_RETURN_ORDER_SUCCESS",
      value: response,
    });

  } catch (error) {
    yield put({
      type: "PROCESS_WMS_RETURN_ORDER_ERROR",
      value: error.response?.data || error,
    });
  } finally {
    yield put({ type: "HIDE_LOADING" });
  }
}

function* confirmReturn(payload) {
  try {
    yield put({ type: "CONFIRM_WMS_RETURN_REQUESTING" });
    yield put({ type: "SHOW_LOADING" });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/returns/process`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
      body: payload.value,
    };

    const response = yield call(requestAxios, requestURL, headers);

    yield put({
      type: "CONFIRM_WMS_RETURN_SUCCESS",
      value: response,
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "success",
        title: "Éxito",
        message: "Devolución procesada correctamente",
      },
    });

  } catch (error) {
    yield put({
      type: "CONFIRM_WMS_RETURN_ERROR",
      value: error.response?.data || error,
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Error",
        message: "Error al procesar la devolución",
      },
    });
  } finally {
    yield put({ type: "HIDE_LOADING" });
  }
}
const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

function* processMassiveReturn({ value }) { 
  try {
    // Resetear el estado antes de procesar el archivo
    yield put({ type: "RESET_PROCESS_MASSIVE_RETURN" });

    yield put({ type: "PROCESS_MASSIVE_RETURN_REQUESTING" });
    yield put({ type: "SHOW_LOADING" });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/wms/order/massiveForReturn`;

    if (!value.file) {
      console.error('No se ha recibido el archivo en la saga');
      return;
    }

    const formData = new FormData();
    formData.append('file', value.file);

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
      body: formData,
    };

    const response = yield call(requestAxios, requestURL, headers);

    console.log('Respuesta del servidor:', response);

    const errorGuides = response.results.filter(result => result.status === 'error');
    const successGuides = response.results.filter(result => result.status === 'success');
    
    // Pasar TODOS los resultados con el nuevo cambio
    yield put({
      type: "PROCESS_MASSIVE_RETURN_SUCCESS",
      value: response.results,
    });

    if (errorGuides.length > 0) {
      const errorCSV = [
        ['Guia', 'Error'],
        ...errorGuides.map(err => [removeAccents(err.guide), removeAccents(err.error)]),
      ];

      const csv = Papa.unparse(errorCSV);

      const blob = new Blob([csv], { type: 'text/csv;charset=windows-1252;' });
      const link = document.createElement('a');
      const currentDate = new Date().toISOString().split('T')[0]; 
      link.href = URL.createObjectURL(blob);
      link.download = `errores_${currentDate}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "danger",
          title: "Error",
          message: "Se encontraron errores en algunas guias. El archivo CSV con los errores ha sido generado.",
        },
      });
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "success",
          title: "Éxito",
          message: "El archivo fue procesado correctamente, sin errores.",
        },
      });
    }

  } catch (error) {
    console.error('Error detallado:', error);

    yield put({
      type: "PROCESS_MASSIVE_RETURN_ERROR",
      value: error.response?.data || error,
    });

    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Error",
        message: error.response?.data?.message || "Error al procesar el archivo",
      },
    });
  } finally {
    yield put({ type: "HIDE_LOADING" });
  }
}


export function* watchWMS() {
  yield takeLatest("FETCH_WMS_RECEIVINGS_REQUEST", fetchIncomings);
  yield takeLatest("FETCH_WMS_ORDER_FOR_RETURN_REQUEST", getOrderForReturn);
  yield takeLatest("FETCH_WMS_ORDER_FOR_RELOCATION_REQUEST", getOrderForRelocation);
  yield takeLatest("CREATE_MANIFEST_REQUEST", createManifest);
  yield takeLatest("FETCH_WMS_RETURNS_REQUEST", fetchReturns);
  yield takeLatest("CREATE_WMS_RETURN_REQUEST", createWMSReturn);
  yield takeLatest("DOWNLOAD_RETURN_CERTIFICATE_REQUEST", downloadReturnCertificate);
  // yield takeLatest("FETCH_WMS_MOVEMENTS_REQUEST", fetchMovements);
  // yield takeLatest("DOWNLOAD_WMS_MOVEMENTS_REQUEST", downloadMovements);
  yield takeLatest("PROCESS_WMS_RETURN_REQUEST", processReturn);
  yield takeLatest("PROCESS_WMS_RETURN_ORDER_REQUEST", processReturnOrder);
  yield takeLatest("CONFIRM_WMS_RETURN_REQUEST", confirmReturn);
  yield takeLatest("FETCH_WMS_MOVEMENTS_REQUEST", fetchMovements);
  yield takeLatest("DOWNLOAD_WMS_MOVEMENTS_REQUEST", downloadMovements);
  yield takeLatest("UPLOAD_GUIDES_FOR_RETURN_ENTRY", processMassiveReturn)
}
