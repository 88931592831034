import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Show } from "../../hooks/Show";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { isNullOrUndefined } from "../../utils/functionsUtils";

function CreatePreOrder({ order, dataCreatePreOrder, successfulCreatePreOrders, reduxGetOrderByTrackingOrIdAddress, reduxCreatePreOrder, reduxResetOrder }){
    const [show, setShow] = useState(false);
    const [validateOrder, setValidateOrder] = useState(false);
    const { register, handleSubmit, reset } = useForm();
    const { handleSubmit: handleSubmitCreateForm, reset: resetCreateForm, register: registerCreate, setValue } = useForm();

    useEffect(() => {
        if(successfulCreatePreOrders && dataCreatePreOrder.hashId){
            const phone = dataCreatePreOrder?.address?.reference1;
            const text = `!Hola¡\n Somos Kiki Latam, te enviamos el link para que realices tu proceso de solicitud de crédito. Agradecemos finalices tu compra. ${process.env.REACT_APP_CHECKOUT}/${dataCreatePreOrder?.hashId ?? ''}`;
            const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(text)}`;
            window.open(url, '_blank');
            handleClose();
        }
    }, [successfulCreatePreOrders]);

    useEffect(() => {
        if(!validateOrder) return;
        if(order && order?.preOrder?.length > 0){
            Swal.fire({
                title: 'Error',
                text: 'Ya existe un crédito para este envío',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            handleClose();
            return;
        }
        if(order && isNullOrUndefined(order.idOrder)){
            Swal.fire({
                title: 'Error',
                text: 'No se pudo obtener el crédito',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            handleClose();
            return;
        }
        if(order){
            setValue('phone', order.address.reference1)
            setValue('email', order.customer.email)
        }
    }, [order]);

    const handleClose = () => {
        reset();
        setShow(false);
        reduxResetOrder();
        resetCreateForm();
        setValidateOrder(false);
    }
    const handleShow = () => setShow(true);

    const renderTooltip = (title) => (
        <Tooltip id="button-tooltip">
            {title}
        </Tooltip>
    );

    const onSubmit = (data) => {
        if(isNullOrUndefined(data.trackingId) && isNullOrUndefined(data.idAddress)){
            Swal.fire({
                title: 'Error',
                text: 'Los campos no pueden estar vacíos',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }
        setValidateOrder(true);
        reduxGetOrderByTrackingOrIdAddress(data);
    }

    const onSubmitCreateForm = (data) => {
        if(isNullOrUndefined(data.email) || isNullOrUndefined(data.phone)){
            Swal.fire({
                title: 'Error',
                text: 'Los campos correo y telefono son requeridos',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return
        }
        reduxCreatePreOrder({idAddress: order.idAddress, ...data});
    }

    return <>
        <div>
            <Show when='feature:pre-orders-create'>
                <OverlayTrigger
                    placement='top'
                    delay={{ show: 50, hide: 50 }}
                    overlay={renderTooltip('Crear crédito')}>
                    <button
                        className='btn btn-primary btn-circle'
                        type='button'
                        onClick={handleShow}>
                        <i className='fas fa-plus fa-sm'></i>
                    </button>
                </OverlayTrigger>
            </Show>
        </div>
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>Nuevo Crédito</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!order &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>id Address</label>
                                    <input type="text" className="form-control" {...register('idAddress')} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Nro Guía</label>
                                    <input type="text" className="form-control" {...register('trackingId')} />
                                </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-center">
                                <button type="submit" className="btn btn-primary">Continuar</button>
                                <button type="button" className="btn btn-secondary ml-3" onClick={handleClose}>Cancelar</button>
                            </div>
                        </div>
                    </form>
                }
                {order && order.idOrder && 
                    <div>
                        <form onSubmit={handleSubmitCreateForm(onSubmitCreateForm)}>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <label for="cliente" className="form-label">Destinatario</label>
                                    <input type="text" className="form-control" id="cliente" value={`${order.address.firstName} ${order.address.lastName}`} disabled/>
                                </div>
                                <div className="col-md-4">
                                    <label for="email" className="form-label">Correo</label>
                                    <input type="email" className="form-control" {...registerCreate('email')} required/>
                                </div>
                                <div className="col-md-4">
                                    <label for="phone" className="form-label">Celular</label>
                                    <input type="phone" className="form-control" {...registerCreate('phone')}  required/>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <label for="id-externo" className="form-label">ID externo</label>
                                    <input type="text" className="form-control" id="id-externo" value={order.externalId} disabled/>
                                </div>
                                <div className="col-md-6">
                                    <label for="fecha-entrega" className="form-label">Fecha de entrega</label>
                                    <input type="date" className="form-control" id="fecha-entrega" value={order.address.deliveryDate} disabled/>
                                </div>
                                {/* <div className="col-md-4">
                                    <label for="correo-remitente" className="form-label">Correo remitente</label>
                                    <input type="email" className="form-control" id="correo-remitente" value={order.address.senderEmail} disabled/>
                                </div> */}
                            </div>

                            <div className="col-12 mb-3">
                                <label for="direccion-envio" className="form-label">Dirección de envío actual</label>
                                <input type="text" className="form-control" id="direccion-envio" value={order.address.address} disabled/>
                            </div>

                            <div className="col-12 mb-3">
                                <label className="form-label">Detalle</label>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID Producto</th>
                                            <th scope="col">Producto</th>
                                            <th scope="col">Precio</th>
                                            <th scope="col">Cantidad</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {order.orderDetails.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.idProduct}</td>
                                                <td>{item.product.name}</td>
                                                <td>{item.product.price}</td>
                                                <td>{item.quantity}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mb-3 d-flex justify-content-end">
                                <div className="form-group d-flex">
                                    <label for="monto" className="form-label">Monto</label>
                                    <input type="text" className="form-control ml-2 mr-4" id="monto" value={order.ammount} disabled/>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <label for="observaciones" className="form-label">Observaciones</label>
                                    <textarea type="text" className="form-control" id="observaciones" value={order.address.reference2} disabled/>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end">
                                <button type="submit" className="btn btn-primary">Crear</button>
                            </div>
                        </form>
                    </div>
                }
            </Modal.Body>
        </Modal>
    </>
}

const mapStateToProps = (state) => {
    return {
        order: state.orderState.order,
        successfulCreatePreOrders: state.preOrderState.successfulCreatePreOrders,
        dataCreatePreOrder: state.preOrderState.dataCreatePreOrder
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetOrderByTrackingOrIdAddress: (payload) =>
            dispatch({
                type: "FETCH_ORDER_BY_TRACKING_OR_ID_ADDRESS",
                value: payload,
            }),
        reduxCreatePreOrder: (payload) =>
            dispatch({
                type: "FETCH_CREATE_PRE_ORDER",
                value: payload,
            }),
        reduxResetOrder: () =>
            dispatch({
                type: "RESET_ORDER_FORM",
            })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePreOrder);