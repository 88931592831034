import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import Grid from '../../../components/Grid';
import { Show } from "../../../hooks/Show";
import { buttonIcon, buttonTypes } from "../../../utils/buttonsUtil";
import AdjustmentTimeLine from "../AdjustmentTimeLine/index"
import { adjustmentMovementsTypes, adjustmentMovementsSubtypes } from "../../../utils/enumsUtil"
import DetailAdjustmentModal from "../../../components/Modal/WMS/Inventory/Adjustments/detailProducts/index";

const AdjustmentsHistoryList = ({
    AdjustmentRecordsById,
    historicalAdjustmentList,
    reduxGetAdjustmentRecords,
    reduxResetAdjustmentRecords,
    reduxGetHistoricalAdjustmentList,
    reduxDownloadCertificate
}) => {

    const [showTimeLineRecordsModal, setShowTimeLineRecordsModal] = useState(false);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [showModalHistoricalDetails, setShowModalHistoricalDetails] = useState(null);
    const [selectedAjustment, setSelectedAjustment] = useState(null);
    const [selectedMovementTypeName, setSelectedMovementTypeName] = useState(null);

    const tableHeaders = [
        "idAjuste",
        "tipo de ajuste",
        "Bodega",
        "Producto",
        "Cantidad del ajuste",
        "Creado por",
    ];

    const columns = [
        {
            title: "Bodega",
            field: 'warehouseName',
            searchable: true,
            render: (rowData) => {
                return <span>{rowData?.details[0]?.inventory.warehouse.name}</span>;
            }
        },
        {
            title: "Tipo de ajuste",
            field: 'adjustmentSubtype',
            searchable: true,
            render: (rowData) => {
                const movementTypeName = adjustmentMovementsSubtypes.find(
                    (el) => el.code == rowData?.idAdjustmentSubType
                )?.label;

                return <span>{movementTypeName}</span>;
            },
        },
        {
            title: "Creado por",
            render: (rowData) => {
                const {firstName, lastName} = rowData.requestingUser
                return <span>{`${firstName} ${lastName}`}</span>;
            },
        },
        {
            title: "Aprobado por",
            render: (rowData) => {
                const {firstName, lastName} = rowData.requestingUser
                return <span>{`${firstName} ${lastName}`}</span>;
            },
        },
        {
            title: "Total del ajuste",
            render: (rowData) => {
                const totalAdjustment = rowData.details.reduce((total, detail) => {
                    return total + (detail.quantity || 0);
                }, 0);

                return <span>{`${totalAdjustment}`}</span>;
            },
        },
        {
            title: "Fecha de gestión",
            render: (rowData) => {
                const createdDate = rowData?.records[0]?.createdDate;
                const formattedDate = createdDate
                    ? new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(createdDate))
                    : "N/A";
            
                return <span>{formattedDate}</span>;
            }
        },
        {
            title: "Acciones",
            render: (rowData) => {
                return (
                    <>
                        {
                            // Se renderiza el botón de descarga de certificado si el tipo de ajuste es por petición de cliente
                            rowData.idAdjustmentSubType === 8 && (
                                <Show when="feature:wms-adjustments-certificate-download">
                                    <button
                                        title="Descargar Certificado"
                                        className={`btn btn-sm btn-circle mr-2 ${buttonTypes.Warning}`}
                                        type="button"
                                        onClick={() => handleDownloadCertificate(rowData)}
                                    >
                                        <i className={buttonIcon.DownloadFile}></i>
                                    </button>
                                </Show>
                            )
                        }
                        <Show when="feature:alerts-enable">
                            <button
                                title="Timeline"
                                className={`btn btn-sm btn-circle mr-2 ${buttonTypes.Success}`}
                                type="button"
                                onClick={() => handleClickTimeLine(rowData)}
                            >
                                <i className={buttonIcon.Info}></i>
                            </button>
                            <button
                                title="Ver detalle"
                                className={`btn btn-sm btn-circle mr-2 ${buttonTypes.Primary}`}
                                type="button"
                                onClick={() => handleClickShowDetailsAdjustment(rowData)}
                            >
                                <i className={buttonIcon.Eye}></i>
                            </button>
                        </Show>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        reduxGetHistoricalAdjustmentList({
            page: currentPage,
            offset: offset,
            search: search,
        });
    }, []);

    const handleDownloadCertificate = (rowData) => {
        reduxDownloadCertificate({
            idAdjustment: rowData.idAdjustment,
        })
    }

    const handleClickTimeLine = (rowData) => {
        setShowTimeLineRecordsModal(true);
        reduxGetAdjustmentRecords(rowData.idAdjustment)
    };

    const handleCloseTimeLineModal = () => {        
        reduxResetAdjustmentRecords();
        setShowTimeLineRecordsModal(false);
    };

    const handleClickShowDetailsAdjustment = (rowData) => {
        const selectedMovementTypeName = adjustmentMovementsSubtypes.find((el) => el.code == rowData?.idAdjustmentSubType)?.label;

        setSelectedAjustment(rowData);
        setSelectedMovementTypeName(selectedMovementTypeName);
        setShowModalHistoricalDetails(true);
    };

    const getSearchQuery = (searchValue) => { return searchValue }

    const handleCloseHistoricalModal = () => { setShowModalHistoricalDetails(false) }

    return (
        <>
            <div className='card shadow mb-4'>
                <div className='card-body'>
                    <Grid
                        cols={columns}
                        data={
                            historicalAdjustmentList && Object.keys(historicalAdjustmentList).length > 0
                                ? historicalAdjustmentList.items
                                : []
                        }
                        page={
                            historicalAdjustmentList && Object.keys(historicalAdjustmentList).length > 0
                                ? Number(historicalAdjustmentList.page)
                                : 1
                        }
                        pages={
                            historicalAdjustmentList && Object.keys(historicalAdjustmentList).length > 0
                                ? Number(historicalAdjustmentList.totalPages)
                                : 0
                        }
                        total={
                            historicalAdjustmentList && historicalAdjustmentList.hasOwnProperty("total") ? historicalAdjustmentList.total : 0
                        }
                        offset={offset}
                        onChangePage={(page) => {
                            setCurrentPage(page);
                            reduxGetHistoricalAdjustmentList({
                                page: page,
                                offset: offset,
                                search: search,
                            });
                        }}
                        onChangeRange={(value) => {
                            setOffset(value);
                            reduxGetHistoricalAdjustmentList({
                                page: 1,
                                offset: value,
                                search: search,
                            });
                        }}
                        defaultValue={search}
                        onChangeSearch={(value) => {
                            setSearch(value);
                            reduxGetHistoricalAdjustmentList({
                                page: 1,
                                offset: offset,
                                search: getSearchQuery(value.trim()),
                            });
                        }}
                    />
                </div>
            </div>
            <DetailAdjustmentModal 
                selectedAjustment={selectedAjustment} 
                selectedMovementTypeName={selectedMovementTypeName} 
                showModalDetails={showModalHistoricalDetails} 
                handleCloseModal={handleCloseHistoricalModal}
            ></DetailAdjustmentModal>
            <AdjustmentTimeLine
                show={showTimeLineRecordsModal}
                handleClose={() => handleCloseTimeLineModal()}
                AdjustmentRecordsById={AdjustmentRecordsById}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        successful: state.forgotPasswordState.successful,
        error: state.forgotPasswordState.error,
        AdjustmentRecordsById: state.adjustmentState.AdjustmentRecordsById,
        historicalAdjustmentList: state.adjustmentState.historicalAdjustmentList
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetAdjustmentRecords: (payload) =>
            dispatch({
                type: "FETCH_GET_ADJUSTMENT_RECORDS_BY_ID",
                value: payload,
            }),
        reduxResetAdjustmentRecords: () =>
            dispatch({
                type: "RESET_ADJUSTMENT_RECORDS_BY_ID"
            }),
        reduxGetHistoricalAdjustmentList: (payload) =>
            dispatch({
                type: "FETCH_GET_HISTORICAL_ADJUSTMENTS",
                value: payload,
            }),
        reduxDownloadCertificate: (payload) =>
            dispatch({
                type: "DOWNLOAD_ADJUSTMENT_CERTIFICATE_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdjustmentsHistoryList);