import { useEffect, useState } from "react";
import Sidebar from "../../../components/Navigation/Sidebar"
import Topbar from "../../../components/Navigation/Topbar";
import Header from "../../../components/Page/header";
import { buttonIcon, buttonTypes } from "../../../utils/buttonsUtil";
import { useHistory } from "react-router";
import DatePicker from 'react-datepicker';
import { useForm, Controller } from "react-hook-form";
import { Show } from "../../../hooks/Show";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import Select from 'react-select'
import Grid from '../../../components/Grid';
import { formatDateWithCustomFormat } from "../../../utils/formatDate"
import { isNullOrUndefined, hasNoCountryLimitations } from "../../../utils/functionsUtils";

const IncomingMovements = ({
    reduxGetProducts,
    products,
    reduxGetWarehouses,
    warehouses,
    reduxGetShippers,
    shippers,
    reduxFetchMovements,
    movements,
    reduxDownloadMovements,
}) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset: resetSearch,
        watch
    } = useForm({
        defaultValues: {
            shipper: null,
            warehouses: null,
            products: null,
            movementType: null,
            movementSubtype: null,
            dates: null,
        }
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    // validar filtro de fechas 
    const [fromDateFormatted, setFromDateFormatted] = useState(null);
    const [toDateFormatted, setToDateFormatted] = useState(null);

    const history = useHistory();

    useEffect(() => {
        reduxGetShippers();
        reduxGetProducts({
            page: 1,
            offset: 1000,
        });
        reduxGetWarehouses({
            page: 1,
            offset: 1000,
        });
        performSearch(null);
    }, [])

    const shipperWatcher = watch("shipper", null);
    const movementTypeWatcher = watch("movementType", null);
    const warehouseWatcher = watch("warehouses", null);
    const productsWatcher = watch("products", null);

    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const countryUser = userData?.idCountry;
    const viewAllCountriesData = hasNoCountryLimitations(userData);

    const performSearch = () => {

        let start = null;
        let end = null;

        if (!isNullOrUndefined(startDate) && !isNullOrUndefined(endDate)) {
            const fromDateFormatted = formatDateWithCustomFormat(startDate);
            const toDateFormatted = formatDateWithCustomFormat(endDate);
            start = `${fromDateFormatted} 00:00:00`;
            end = `${toDateFormatted} 23:59:59`;
        }

        setFromDateFormatted(start);
        setToDateFormatted(end);

        reduxFetchMovements({
            page: 1,
            offset: offset,
            shipper: shipperWatcher && shipperWatcher?.value || null,
            warehouses: warehouseWatcher && warehouseWatcher?.map(e => e.value).join(',') || null,
            products: productsWatcher && productsWatcher?.map(e => e.value).join(',') || null,
            movementType: movementTypeWatcher && movementTypeWatcher?.value || null,
            startDate: start || null,
            endDate: end || null,
        })

    }

    const changeDates = (dates) => {
        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);
    };

    const renderMovementTypeColumn = (rowData) => {
        const typeMap = {
            INCOMING: "Ingreso",
            OUTGOING: "Salida",
        };
    
        const subtypeMap = {
            ADJUSTMENT_CLI: `Ajuste por petición de cliente - ${typeMap[rowData.type]}`,
            ADJUSTMENT_QTY: `Ajuste por diferencia de cantidades - ${typeMap[rowData.type]}`,
        };
    
        if (subtypeMap[rowData.subtype]) {
            return <span>{subtypeMap[rowData.subtype]}</span>;
        }
    
        if (rowData.type === "INCOMING" && rowData.subtype === "RETURN") {
            return <span>Devolucion</span>;
        }
    
        if (typeMap[rowData.type]) {
            return <span>{typeMap[rowData.type]}</span>;
        }
    
        return <span></span>;
    };

    const actionButtons = [
        {
            onClick: () => history.push("/inventory"),
            description: "Regresar a Inventario",
            buttonType: buttonTypes.Primary,
            buttonIcon: buttonIcon.Arrow_LeftReply,
        },
        {
            permission: 'feature:wms-inventory-receivings-process',
            onClick: () => history.push("/wms/receiving"),
            description: "Procesar recepciones",
            buttonType: buttonTypes.Success,
            buttonIcon: buttonIcon.Inbox,
        },
        {
            permission: 'feature:wms-inventory-returns-process',
            onClick: () => history.push("/wms/pending-returns"),
            description: "Procesar devoluciones",
            buttonType: buttonTypes.Success,
            buttonIcon: buttonIcon.Exchange,
        },
        {
            permission: 'feature:wms-inventory-relocation-process',
            onClick: () => history.push("/wms/incomingRelocations"),
            description: "Procesar ingreso de traslados",
            buttonType: buttonTypes.Success,
            buttonIcon: buttonIcon.TruckMoving,
        },
        {
            permission: 'feature:wms-inventory-relocation-process',
            onClick: () => history.push("/wms/relocation"),
            description: "Procesar egreso de traslados",
            buttonType: buttonTypes.Success,
            buttonIcon: buttonIcon.Forklift,
        },
        {
            permission: 'feature:wms-inventory-adjustments-module-show',
            onClick: () => history.push("/wms/adjustments-handler"),
            description: "Procesar Ajustes",
            buttonType: buttonTypes.Success,
            buttonIcon: buttonIcon.Edit
        },
    ];

    const columns = [
        {
            title: "Id Movimiento",
            render: (rowData) => {
                return <span>{rowData.idMovement}</span>;
            },
        },
        {
            title: "Bodega",
            render: (rowData) => {
                return <span>{rowData.inventory.subWarehouse.name}</span>;
            },
        },
        {
            title: "Producto",
            render: (rowData) => {
                return <span>{rowData.inventory.product.name}</span>;
            },
        },
        {
            title: "Remitente",
            render: (rowData) => {
                return <span>{rowData.inventory.product.company.description}</span>;
            },
        },
        {
            title: "Cantidad Total",
            render: (rowData) => {
                return <span>{rowData.quantity}</span>;
            },
        },
        {
            title: "Notas",
            render: (rowData) => {
                return <span>{rowData.notes}</span>;
            },
        },
        {
            title: "Tipo Movimiento",
            render: (rowData) => renderMovementTypeColumn(rowData)
        },
        {
            title: "Fecha",
            render: (rowData) => {
                return <span>{formatDateWithCustomFormat(rowData.date, { format: 'DD/MM/yyyy hh:mm:ss' })}</span>;
            },
        },
        {
            title: "Num Lote",
            render: (rowData) => {
                return <span>{rowData.wmsLots ? `${rowData.wmsLots.number}` : ''}</span>;
            },
        },
        {
            title: "Serial Lote",
            render: (rowData) => {
                return <span>{rowData.wmsLots ? `${rowData.wmsLots.serial}` : ''}</span>;
            },
        },
        {
            title: "Exp Lote",
            render: (rowData) => {
                return <span>{rowData.wmsLots ? `${new Date(rowData.wmsLots.dateExp).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' })}` : ''}</span>;
            },
        },
    ]

    const downloadMovementsReport = () => {
        let start = null;
        let end = null;

        if (!isNullOrUndefined(startDate) && !isNullOrUndefined(endDate)) {
            const fromDateFormatted = formatDateWithCustomFormat(startDate);
            const toDateFormatted = formatDateWithCustomFormat(endDate);
            start = `${fromDateFormatted} 00:00:00`;
            end = `${toDateFormatted} 23:59:59`;
        }
        setFromDateFormatted(start);
        setToDateFormatted(end);

        reduxDownloadMovements({
            shipper: shipperWatcher && shipperWatcher?.value || null,
            warehouses: warehouseWatcher && warehouseWatcher?.map(e => e.value).join(',') || null,
            products: productsWatcher && productsWatcher?.map(e => e.value).join(',') || null,
            movementType: movementTypeWatcher && movementTypeWatcher?.value || null,
            startDate: start || null,
            endDate: end || null,
        });
    }

    return (
        <>
            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Topbar />
                        <div className="container-fluid">
                            <Header title={"Movimientos de inventario"} subtitle={"Módulo para la gestión de los movimientos de inventario"} actionButtons={actionButtons} />
                        </div>
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    Listado de ingresos
                                </h6>
                            </div>
                            <div className="card-body">
                                <form className="d-flex" onSubmit={handleSubmit(performSearch)}>
                                    <Show whenNot={["feature:isClient"]}>
                                        <div className='form-group w-25 mr-2'>
                                            <label
                                                htmlFor='shipper'
                                                className='form-label'>
                                                Remitente
                                            </label>
                                            <Controller
                                                control={control}
                                                name="shipper"
                                                render={({ field }) => (
                                                    <>
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            options={
                                                                shippers && Object.keys(shippers).length > 0
                                                                    ? shippers.items
                                                                        .filter(e => e.isActive === true && 
                                                                            (viewAllCountriesData || e.country == countryUser && countryUser))
                                                                        .map((ele, key) => ({
                                                                            value: ele.idCompany,
                                                                            label: ele.description,
                                                                        }))
                                                                    : []
                                                            }
                                                        />
                                                        {
                                                            errors.shipper &&
                                                            <span className="error-message">{errors.shipper.message}</span>
                                                        }
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </Show>
                                    <div className='form-group w-25 mr-2'>
                                        <label
                                            htmlFor='warehouses'
                                            className='form-label'>
                                            Bodega
                                        </label>
                                        <Controller
                                            control={control}
                                            name="warehouses"
                                            render={({ field }) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        isMulti
                                                        isClearable
                                                        options={
                                                            warehouses && warehouses.items
                                                                ? warehouses.items
                                                                    .filter(e => e.isActive === true && 
                                                                        (viewAllCountriesData || countryUser && e.warehouseHubs[0]?.hub?.idCountry == countryUser))
                                                                    .map(ele => ({
                                                                        value: ele.idWarehouse,
                                                                        label: ele.name || ele.warehouseCode,
                                                                    }))
                                                                :
                                                                []
                                                        }
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className='form-group w-25 mr-2'>
                                        <label
                                            htmlFor='products'
                                            className='form-label'>
                                            Producto
                                        </label>
                                        <Controller
                                            control={control}
                                            name="products"
                                            render={({ field }) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        isMulti
                                                        isClearable
                                                        isDisabled={!shipperWatcher}
                                                        options={
                                                            products && products.items
                                                                ? products.items
                                                                    .filter(e => e.isActive === true && shipperWatcher && e.idShipper == shipperWatcher.value)
                                                                    .map(ele => ({
                                                                        value: ele.idProduct,
                                                                        label: ele.name,
                                                                    }))
                                                                : []
                                                        }
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className='form-group w-25 mr-2'>
                                        <label
                                            htmlFor='movementType'
                                            className='form-label'>
                                            Tipo de Movimiento
                                        </label>
                                        <Controller
                                            control={control}
                                            name="movementType"
                                            render={({ field }) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        isClearable
                                                        options={
                                                            [
                                                                { value: "INCOMING", label: "Ingreso" },
                                                                { value: "OUTGOING", label: "Salida" },
                                                                { value: "RETURN", label: "Devolución" },
                                                                { value: "ADJUSTMENT", label: "Ajuste" },
                                                            ]
                                                        }
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className='form-group w-25 mr-2'>
                                        <label
                                            htmlFor='dates'
                                            className='form-label'>
                                            Desde - Hasta *
                                        </label>
                                        <Controller
                                            control={control}
                                            name="dates"
                                            render={({ field }) => (
                                                <>
                                                    <DatePicker
                                                        className={'form-control'}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        selectsRange
                                                        onChange={(value) => {
                                                            field.onChange(value);
                                                            changeDates(value);
                                                        }}
                                                        dateFormat="yyyy-MM-dd"
                                                        maxDate={new Date()}
                                                        shouldCloseOnSelect={false}
                                                        isClearable
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className='form-group mr-2 mt-4'>
                                        <Button
                                            variant='primary'
                                            type='submit'
                                            id='btnSearch'>
                                            Buscar
                                        </Button>
                                    </div>
                                    <div className='form-group mr-2 mt-4'>
                                        <Button
                                            variant='primary'
                                            onClick={() => downloadMovementsReport(movements.items)}
                                            id='btnSearch'>
                                            Descargar
                                        </Button>
                                    </div>
                                </form>

                                <Grid
                                    showFilters={false}
                                    cols={columns}
                                    data={
                                        movements && Object.keys(movements).length > 0 ? movements.items : []
                                    }
                                    page={
                                        movements && Object.keys(movements).length > 0
                                            ? Number(movements.page)
                                            : currentPage
                                    }
                                    pages={
                                        movements && Object.keys(movements).length > 0
                                            ? Number(movements.totalPages)
                                            : 0
                                    }
                                    total={
                                        movements && movements.hasOwnProperty("total") ? movements.total : 0
                                    }
                                    offset={offset}
                                    onChangePage={(value) => {
                                        setCurrentPage(value)
                                        reduxFetchMovements({
                                            page: value,
                                            offset: offset,
                                            shipper: shipperWatcher && shipperWatcher?.value || null,
                                            warehouses: warehouseWatcher && warehouseWatcher?.map(e => e.value).join(',') || null,
                                            products: productsWatcher && productsWatcher?.map(e => e.value).join(',') || null,
                                            movementType: movementTypeWatcher && movementTypeWatcher?.value || null,
                                            startDate: fromDateFormatted || null,
                                            endDate: toDateFormatted || null,
                                        });
                                    }}
                                    onChangeRange={(value) => {
                                        setOffset(value);
                                        reduxFetchMovements({
                                            page: 1,
                                            offset: value,
                                            shipper: shipperWatcher && shipperWatcher?.value || null,
                                            warehouses: warehouseWatcher && warehouseWatcher?.map(e => e.value).join(',') || null,
                                            products: productsWatcher && productsWatcher?.map(e => e.value).join(',') || null,
                                            movementType: movementTypeWatcher && movementTypeWatcher?.value || null,
                                            startDate: fromDateFormatted || null,
                                            endDate: toDateFormatted || null,
                                        });
                                    }}
                                >
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        incomings: state.wmsState.incomings,
        products: state.productState.products,
        shippers: state.companyState.clients,
        warehouses: state.warehouseState.warehouses,
        movements: state.wmsState.movements,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxFetchIncomings: (payload) =>
            dispatch({
                type: "FETCH_WMS_RECEIVINGS_REQUEST",
                value: payload,
            }),
        reduxGetProducts: (payload) =>
            dispatch({
                type: "FETCH_PRODUCTS_REQUEST",
                value: payload,
            }),
        reduxGetShippers: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
                value: payload,
            }),
        reduxGetWarehouses: (payload) =>
            dispatch({
                type: "FETCH_WAREHOUSES_REQUEST",
                value: payload,
            }),
        reduxFetchMovements: (payload) =>
            dispatch({
                type: "FETCH_WMS_MOVEMENTS_REQUEST",
                value: payload,
            }),
        reduxDownloadMovements: (payload) =>
            dispatch({
                type: "DOWNLOAD_WMS_MOVEMENTS_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomingMovements)