import { useEffect, useState, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Modal, Button, OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import moment from 'moment';
import { Show } from "../../../../../hooks/Show";
import { buttonIcon, buttonTypes } from "../../../../../utils/buttonsUtil";

// Componentes
import Sidebar from "../../../../../components/Navigation/Sidebar";
import Topbar from "../../../../../components/Navigation/Topbar";
import Header from "../../../../../components/Page/header";
import Grid from "../../../../../components/Grid";
import GuideScanner from "../../../../../components/Inputs/Scanner/GuideScanner";


const ReturnsEntry = ({
    reduxGetWMSOrderForReturn,
    successfulFetchWMSOrderForReturn,
    errorsFetchWMSOrderForReturn,
    reduxResetWMSOrderForReturn,
    wmsOrderForReturn,
    reduxCreateWMSReturn,
    successfulCreateWMSReturn,
    errorsCreateWMSReturn,
    createWMSReturnResult,
    reduxDownloadReturnCertificate,
    successfulDownloadReturnCertificate,
    errorsDownloadReturnCertificate,
    reduxResetAll,
    reduxUploadGuidesForReturn,
    successfulMassiveReturn,
    massiveReturnResult,
    errorsMassiveReturn,
}) => {
    const history = useHistory();
    const [scannedOrders, setScannedOrders] = useState([]);
    
    const {
        control,
        formState: { errors },
        setValue,
        getValues,
        setError,
        reset,
        handleSubmit
    } = useForm({
        defaultValues: {
            trackingOrReturn: '',
        }
    });

    const trackingOrReturnRef = useRef(null);
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);
    const [fileName, setFileName] = useState("Haz clic o arrastra el archivo aquí");
    const [isFileUploaded, setIsFileUploaded] = useState(false); 
    const [file, setFile] = useState(null);


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        console.log('Archivo seleccionado:', selectedFile); 
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
            setIsFileUploaded(true);
        }
    };
    
    
      const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile) {
            setFile(droppedFile);
            setFileName(droppedFile.name);
            setIsFileUploaded(true);
        }
    };

    const handleUploadFile = () => {
        if (file) {
            const allowedTypes = ['text/csv'];
            console.log('Archivo seleccionado:', file);
    
            if (!allowedTypes.includes(file.type)) {
                console.error('Tipo de archivo no permitido:', file.type);
                alert('Por favor, sube un archivo CSV');
                return;
            }
    
            reduxUploadGuidesForReturn({ file });
    
        } else {
            console.log('No se ha seleccionado ningún archivo');
            alert('Por favor, selecciona un archivo');
        }
    };


    
    
    useEffect(() => {
        if (successfulMassiveReturn && massiveReturnResult && massiveReturnResult.length > 0) {
            console.log('Full massiveReturnResult:', massiveReturnResult);
    
            const successfulGuides = massiveReturnResult.filter(
                guide => guide.status === 'success'
            );
    
            console.log('Successful Guides:', successfulGuides);
    
            if (successfulGuides.length > 0) {
                const newOrders = successfulGuides.map(guide => ({
                    trackingId: guide.guide,
                    product: guide.result?.address?.product || 'Producto desconocido',
                    numOrder: guide.result?.numOrder || null,
                    idOperator: guide.result?.address?.idOperator || null,
                }));
    
                console.log('New Orders:', newOrders);
    
                setScannedOrders(prevOrders => [...prevOrders, ...newOrders]);
    
                Swal.fire({
                    title: 'Éxito',
                    text: `${newOrders.length} guías se han agregado correctamente.`,
                    icon: 'success',
                    confirmButtonText: 'Aceptar',
                }).then(() => {
                    handleCloseModal();
                });
            }
            setFile(null);
            setFileName("Haz clic o arrastra el archivo aquí");
            setIsFileUploaded(false);
        }
    }, [successfulMassiveReturn, massiveReturnResult]);

    useEffect(() => {
        const cleanupForm = () => {
            reset({
                trackingOrReturn: ''
            });
            setScannedOrders([]);
            reduxResetWMSOrderForReturn();
            setError('trackingOrReturn', null);
        };

        cleanupForm();

        if (trackingOrReturnRef.current) {
            trackingOrReturnRef.current.focus();
        }

        return () => cleanupForm();
    }, []); 

    useEffect(() => {
        if (successfulFetchWMSOrderForReturn === true) {
            const scannedGuide = getValues('trackingOrReturn');
            
            if (!wmsOrderForReturn) {
                Swal.fire({
                    title: 'Error',
                    text: `La guía ${scannedGuide} no existe en el sistema`,
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
                setValue('trackingOrReturn', '');
                trackingOrReturnRef.current.focus();
                return;
            }
            
            setScannedOrders(prevOrders => [...prevOrders, {
                trackingId: scannedGuide,
                product: wmsOrderForReturn.address.product,
                numOrder: wmsOrderForReturn.numOrder,
                idOperator: wmsOrderForReturn.address.idOperator
            }]);
            
            setValue('trackingOrReturn', '');
            trackingOrReturnRef.current.focus();
            return;
        }

        if (errorsFetchWMSOrderForReturn === true) {
            const invalidGuide = getValues('trackingOrReturn');
            
            Swal.fire({
                title: 'Error',
                text: getErrorMessage(invalidGuide),
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });

            setValue('trackingOrReturn', '');
            trackingOrReturnRef.current.focus();
            return;
        }
    }, [successfulFetchWMSOrderForReturn, errorsFetchWMSOrderForReturn]);

    useEffect(() => {
        if (successfulCreateWMSReturn) {
            if (createWMSReturnResult?.item?.idReturn) {
                try {
                    reduxDownloadReturnCertificate(createWMSReturnResult.item.idReturn);
                } catch (error) {
                    console.error('Error al iniciar la descarga:', error);
                    Swal.fire({
                        title: 'Error',
                        text: 'No se pudo descargar el certificado. Por favor, inténtelo más tarde.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                    });
                }
            }

            Swal.fire({
                title: 'Éxito',
                text: createWMSReturnResult?.message,
                icon: 'success',
                confirmButtonText: 'Aceptar',
            }).then(() => {
                setScannedOrders([]);
            });
        }

        if (errorsCreateWMSReturn) {
            const errorMessage = createWMSReturnResult?.message || 
                               createWMSReturnResult?.data?.message || 
                               'Ha ocurrido un error al crear la devolución';
            
            Swal.fire({
                title: 'Error',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
        }
    }, [successfulCreateWMSReturn, errorsCreateWMSReturn, createWMSReturnResult]);
      
    useEffect(() => {
        const handleBeforeUnload = () => {
            reset({
                trackingOrReturn: ''
            });
            setScannedOrders([]);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [reset]);

    useEffect(() => {
        if (successfulCreateWMSReturn && successfulDownloadReturnCertificate) {
            Swal.fire({
                title: 'Éxito',
                text: createWMSReturnResult?.message,
                icon: 'success',
                confirmButtonText: 'Aceptar',
            }).then(() => {
                setValue('trackingOrReturn', '');
                setScannedOrders([]);
                setFile(null);
                setFileName("Haz clic o arrastra el archivo aquí");
                setIsFileUploaded(false);
                reduxResetAll();
                
                if (trackingOrReturnRef.current) {
                    trackingOrReturnRef.current.focus();
                }
            });
        }
    }, [successfulCreateWMSReturn, successfulDownloadReturnCertificate]);

    useEffect(() => {
        return () => {
            setValue('trackingOrReturn', '');
            setScannedOrders([]);
            reduxResetAll();
        };
    }, []);

    useEffect(() => {
        if (successfulCreateWMSReturn && successfulDownloadReturnCertificate) {
            Swal.fire({
                title: 'Éxito',
                text: createWMSReturnResult?.message,
                icon: 'success',
                confirmButtonText: 'Aceptar',
            }).then(() => {
                setValue('trackingOrReturn', '');
                setScannedOrders([]);
                reduxResetAll();
                
                if (trackingOrReturnRef.current) {
                    trackingOrReturnRef.current.focus();
                }
            });
        }
    }, [successfulCreateWMSReturn, successfulDownloadReturnCertificate]);

    useEffect(() => {
        return () => {
            setValue('trackingOrReturn', '');
            setScannedOrders([]);
            reduxResetAll();
        };
    }, []);

    const getErrorMessage = (guide) => {
        if (!guide || guide.trim() === '') {
            return 'La guía no puede estar vacía';
        }
        
        if (scannedOrders.includes(guide)) {
            return `La guía ${guide} ya ha sido escaneada`;
        }

        return `La guía ${guide} no es válida o no existe en el sistema`;
    };

    const searchOrder = (data) => {
        const guide = data.trackingOrReturn?.trim() || '';

        if (!guide) {
            reset({
                trackingOrReturn: ''
            });
            if (trackingOrReturnRef.current) {
                trackingOrReturnRef.current.focus();
            }
            return;
        }

        // Validar si la guía ya fue escaneada
        if (scannedOrders.includes(guide)) {
            Swal.fire({
                title: 'Advertencia',
                text: `La guía ${guide} ya ha sido escaneada`,
                icon: 'warning',
                confirmButtonText: 'Aceptar'
            });
            setValue('trackingOrReturn', '');
            trackingOrReturnRef.current.focus();
            return;
        }

        try {
            reduxGetWMSOrderForReturn({
                trackingOrReturn: guide
            });
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Ha ocurrido un error al procesar la guía',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            console.error('Error al procesar la guía:', error);
        }
    };

    const handleCreateReturn = () => {
        if (scannedOrders.length === 0) {
            Swal.fire({
                title: 'Advertencia',
                text: 'Debe escanear al menos una guía para procesar la devolución',
                icon: 'warning',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        const returnDetail = scannedOrders.map(order => ({
            numOrder: order.numOrder,
        }));

        const payload = {
            idOperator: scannedOrders.length > 0 ? scannedOrders[0].idOperator : null,
            returnDetail,
        };

        console.log('Payload for reduxCreateWMSReturn:', payload);

        reduxCreateWMSReturn(payload);
    };
    const userData = JSON.parse(localStorage.getItem("userData"));

    const hasPermission = userData?.role?.permissionsRoles?.some(
        (perm) => perm.idPermission === 320
    );

    const actionButtons = useMemo(() => {
        const buttons = [
            {
                onClick: () => history.push("/wms/pending-returns"),
                description: "Regresar a Ingresos",
                buttonType: buttonTypes.Primary,
                buttonIcon: buttonIcon.Arrow_LeftReply,
            },
        ];
    
        if (hasPermission) {
            buttons.push({
                onClick: handleOpenModal, 
                description: "Ingreso Masivo en Procesar Devoluciones",
                buttonType: buttonTypes.Warning,
                buttonIcon: buttonIcon.UploadFile,
            });
        }
    
        return buttons;
    }, [history, hasPermission]);

    const columns = [
        {
            title: "Guía",
            field: "trackingId",
            searchable: true,
            render: (rowData) => <span>{rowData.trackingId}</span>
        },
        {
            title: "Producto",
            field: "product",
            searchable: true,
            render: (rowData) => <span>{rowData.product || 'N/A'}</span>
        },
        {
            title: "Acciones",
            render: (rowData) => {
                return (
                    <button
                        title='Eliminar'
                        className="btn btn-danger btn-sm btn-circle"
                        type="button"
                        onClick={() => handleRemoveRow(rowData)}
                    >
                        <i className="fas fa-trash fa-xs"></i>
                    </button>
                );
            }
        }
    ];

    const handleRemoveRow = (rowData) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: `¿Deseas eliminar la guía ${rowData.trackingId}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                setScannedOrders(prevOrders => 
                    prevOrders.filter(order => order.trackingId !== rowData.trackingId)
                );
                Swal.fire(
                    '¡Eliminado!',
                    `La guía ${rowData.trackingId} ha sido eliminada.`,
                    'success'
                );
            }
        });
    };

    return (
        <div id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Topbar />
                    <div className="container-fluid">
                        <Header 
                            title="Ingreso de Devoluciones"
                            actionButtons={actionButtons} 
                        />
                        
                        <div className="card shadow">
                            <div className="card-body p-0">
                                {/* Sección Superior - Escáner */}
                                <div className="p-4 border-bottom">
                                    <form onSubmit={handleSubmit(searchOrder)}>
                                        <div className="row align-items-end">
                                            <div className="col-12 col-md-6">
                                                <h6 className="font-weight-bold text-primary mb-3">
                                                    Escanear Devolución
                                                </h6>
                                                <div className="form-group mb-0">
                                                    <Controller
                                                        control={control}
                                                        name="trackingOrReturn"
                                                        rules={{
                                                            required: "Este campo es requerido"
                                                        }}
                                                        render={({ field }) => (
                                                            <div className="input-group">
                                                                <GuideScanner
                                                                    {...field}
                                                                    ref={trackingOrReturnRef}
                                                                    className="form-control form-control-user"
                                                                    placeholder="Escanea o ingresa la guía..."
                                                                    onScan={(value) => setValue('trackingOrReturn', value)}
                                                                />
                                                                {errors.trackingOrReturn && (
                                                                    <span className="error-message text-danger">
                                                                        {errors.trackingOrReturn.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                {/* Sección Inferior - Listado */}
                                <div className="p-4">
                                    <h6 className="font-weight-bold text-primary mb-3 d-flex justify-content-between align-items-center">
                                        <span>Listado de Devoluciones Escaneadas</span>
                                        <span>Guías procesadas: {scannedOrders.length}</span>
                                    </h6>

                                    <div style={{ maxHeight: 'calc(100vh - 400px)', overflow: 'auto' }}>
                                        <Grid
                                            cols={columns}
                                            data={scannedOrders}
                                            showFilters={false}
                                            enableFixedColumns={true}
                                            stickyHeader={true}
                                            showRange={false}
                                            style={{ 
                                                maxHeight: '100%',
                                                overflow: 'auto'
                                            }}
                                        />
                                    </div>
                                    {/* Confirm Button */}
                                    <div className="mt-3 text-center">
                                        <Button 
                                            variant="primary" 
                                            onClick={handleCreateReturn}
                                        >
                                            Confirmar
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={isModalOpen} onHide={handleCloseModal} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Ingreso Masivo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group controlId="fileUpload">
                                    <Form.Label className="text-primary mb-3" style={{ fontWeight: "bold" }}>
                                        Selecciona o arrastra un archivo para subir:
                                    </Form.Label>
                                    <div
                                        className="file-upload-wrapper"
                                        style={{
                                            border: `2px dashed ${isFileUploaded ? "#28a745" : "#007bff"}`,
                                            borderRadius: "10px",
                                            padding: "30px",
                                            textAlign: "center",
                                            backgroundColor: isFileUploaded ? "#d4edda" : "#f4f8ff",
                                            color: isFileUploaded ? "#28a745" : "#007bff",
                                            cursor: "pointer",
                                            position: "relative",
                                            transition: "0.3s",
                                        }}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={handleDrop}
                                    >
                                        <i
                                            className="bi bi-cloud-upload"
                                            style={{
                                                fontSize: "3rem",
                                                marginBottom: "10px",
                                                color: isFileUploaded ? "#28a745" : "#007bff",
                                            }}
                                        ></i>
                                        <p style={{ margin: 0 }}>{fileName}</p>
                                        <Form.Control
                                            type="file"
                                            style={{
                                                opacity: 0,
                                                position: "absolute",
                                                height: "100%",
                                                width: "100%",
                                                top: 0,
                                                left: 0,
                                                cursor: "pointer",
                                            }}
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Cancelar
                            </Button>
                            <Button variant="primary" onClick={handleUploadFile} disabled={!file}>
                                Subir Archivo
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>

    );
};

const mapStateToProps = (state) => {
    return {
      // Estado relacionado con la obtención de pedidos para retorno
      successfulFetchWMSOrderForReturn: state.wmsState.successfulFetchWMSOrderForReturn,
      errorsFetchWMSOrderForReturn: state.wmsState.errorsFetchWMSOrderForReturn,
      wmsOrderForReturn: state.wmsState.wmsOrderForReturn,
  
      // Estado relacionado con la creación de retorno de WMS
      successfulCreateWMSReturn: state.wmsState.successfulCreateWMSReturn,
      errorsCreateWMSReturn: state.wmsState.errorsCreateWMSReturn,
      createWMSReturnResult: state.wmsState.createWMSReturnResult, 
  
      // Estado relacionado con la descarga del certificado de retorno
      successfulDownloadReturnCertificate: state.wmsState.successfulDownloadReturnCertificate,
      errorsDownloadReturnCertificate: state.wmsState.errorsDownloadReturnCertificate,
  
      // Estado relacionado con la carga de guías
      uploadingGuides: state.wmsState.uploadingGuides,
      successfulUploadGuides: state.wmsState.successfulUploadGuides,
      errorsUploadGuides: state.wmsState.errorsUploadGuides,
  
      // Estado relacionado con la carga masiva de guías
      successfulMassiveReturn: state.wmsState.successfulMassiveReturn,
      massiveReturnResult: state.wmsState.massiveReturnResult,
      errorsMassiveReturn: state.wmsState.errorsMassiveReturn,
    };
  };
  

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetWMSOrderForReturn: (payload) =>
            dispatch({
                type: "FETCH_WMS_ORDER_FOR_RETURN_REQUEST",
                value: payload,
            }),
        reduxResetWMSOrderForReturn: () =>
            dispatch({
                type: "RESET_WMS_ORDER_FOR_RETURN"
            }),
        reduxCreateWMSReturn: (payload) =>
            dispatch({
                type: "CREATE_WMS_RETURN_REQUEST",
                value: payload,
            }),
        reduxDownloadReturnCertificate: (idReturn) =>
            dispatch({
                type: "DOWNLOAD_RETURN_CERTIFICATE_REQUEST",
                value: idReturn,
            }),

        reduxUploadGuidesForReturn: (payload) =>
             dispatch({
                 type: "UPLOAD_GUIDES_FOR_RETURN_ENTRY",
                 value: payload,
              }),

        reduxResetAll: () => {
            dispatch({ type: "RESET_WMS_ORDER_FOR_RETURN" });
            dispatch({ type: "RESET_CREATE_WMS_RETURN" });
            dispatch({ type: "RESET_DOWNLOAD_RETURN_CERTIFICATE" });
            dispatch({ type: "RESET_WMS_MASSIVE_FOR_RETURN" });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnsEntry);