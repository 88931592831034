import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { MdContentCopy } from 'react-icons/md';

const CustomReturnsProcess = ({ data, scannedGuides, editedGuides }) => {
    const [expandedGuide, setExpandedGuide] = useState(null); 

    const handleRowClick = (guide) => {
        setExpandedGuide(expandedGuide === guide.tracking ? null : guide.tracking);
    };

    return (
        <div className="container-fluid p-0">
            <div className="card shadow-sm border-0">
                <div className="card-body p-0" style={{ maxHeight: '80.9vh', overflowY: 'auto' }}>
                    <Table striped hover responsive className="mb-0 w-100">

                        <tbody>
                            {data.map((item, index) => (
                                <React.Fragment key={index}>
                                    <tr
                                        onClick={() => handleRowClick(item)} 
                                        style={{ 
                                            cursor: "pointer", 
                                            backgroundColor: editedGuides.includes(item.tracking) ? 'lightgreen' : 'transparent'
                                        }}
                                    >
                                        <td className="">
                                            {item.tracking}
                                            <button onClick={() => navigator.clipboard.writeText(item.tracking)} style={{ background: 'none', border: 'none', padding: 0, marginLeft: '12px' }}>
                                                <MdContentCopy style={{ cursor: 'pointer', fontSize: '16px', color: '#afafaf' }} />
                                            </button>
                                        </td>
                                    </tr>

                                    {expandedGuide === item.tracking && (
                                        <tr>
                                            <td>
                                                <div>
                                                    <strong>{item.product}</strong> - {item.quantity} unidades
                                                </div>
                                                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginTop: "5px" }}>
                                                    <span><strong>Disponible:</strong> {item.available}</span>
                                                    <span><strong>Averiado:</strong> {item.damaged}</span>
                                                    <span><strong>Faltante:</strong> {item.missing}</span>
                                                    <span><strong>Extra:</strong> {item.extra}</span>
                                                    <span><strong>Caducado:</strong> {item.expired}</span>
                                                    <span><strong>Suplantación:</strong> {item.supplanted}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default CustomReturnsProcess;
